import React from "react";
import { FormControlLabel, FormControl, FormGroup, Grid, Checkbox, Box } from "@mui/material";
import { ExperienceLevelOptions } from "../../Constants";
import { useState } from "react";

function FilterByExp({ onChecked }) {
  const [checkedState, setCheckedState] = useState({});
  const handleCheckedChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState((prevState) => ({
      ...prevState,
      [name]: checked
    }));
    onChecked({ checked: checked, exp: name });
  };

  return (
    <Box>
      <h4>Filter by Experience</h4>
      <FormControl component={"fieldset"}>
        <FormGroup>
          <Grid container maxWidth={"300px"}>
            {ExperienceLevelOptions.map((option) => {
              return (
                <Grid item xs={6} key={option.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!checkedState[option.label]}
                        name={option.label}
                        onChange={handleCheckedChange}
                      />
                    }
                    label={option.label}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </FormControl>
    </Box>
  );
}

export default FilterByExp;
