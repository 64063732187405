import { deleteJob } from "app/api/job_api";
import { useMutation } from "@tanstack/react-query";
const useDeleteJob = ({ id, updateDelete, onSuccess, onError, onSettled }) => {
  return useMutation({
    mutationFn: (params) => deleteJob(id, updateDelete),
    onSuccess: () => onSuccess(),
    onError: () => onError(),
    onSettled: () => onSettled()
  });
};

export default useDeleteJob;
