import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import { forwardRef } from "react";
import { useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { H6 } from "app/components/Typography";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ title, open, handleClose, children }) {
  const theme = useTheme();
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <H6 sx={{ flex: 1, marginLeft: theme.spacing(2) }}>{title}</H6>
        </Toolbar>
      </AppBar>
      {children}
    </Dialog>
  );
}
