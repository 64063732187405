import React from "react";
import { Box, Button, DialogActions, DialogContent, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import { H1 } from "app/components/Typography";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import useAuth from "app/hooks/useAuth";
import { useState } from "react";
import { useUpdatePassword } from "app/hooks/candidate/useUpdatePassword";
import { useUpdateCompanyPass } from "app/hooks/company/useUpdateCompanyPass";
import { useUpdateAdminPass } from "app/hooks/admin/useUpdateAdminPass";
import FormPasswordField from "app/components/form-components/FormPasswordField";
import CustomDialog from "app/components/CustomDialog";
function ChangePassword() {
  const { control, handleSubmit, setError } = useForm();
  const { user, logout } = useAuth();
  const [updateError, setUpdateError] = useState({ error: false, message: "" });
  const [updateSuccess, setUpdateSuccess] = useState({ success: false, message: "" });

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [newPassword, setNewPassword] = useState({});

  const onUpdateSuccess = (resObj) => {
    const status = resObj.response ? resObj.response.status : resObj.status;
    if (status === 401) {
      const data = resObj.response.data;
      setUpdateError((prev) => ({ error: true, message: data.message }));
    }
    if (status === 200) {
      setUpdateSuccess((prev) => ({ success: true, message: "Password changed successfully" }));
      logout();
    }
  };

  const { mutate: updateCandidatePass } = useUpdatePassword({
    onSuccess: onUpdateSuccess,
    onError: (err) => console.log("error", err)
  });

  const { mutate: updateCompanyPass } = useUpdateCompanyPass({
    onSuccess: onUpdateSuccess
  });

  const { mutate: updateAdminPass } = useUpdateAdminPass({
    onSuccess: onUpdateSuccess
  });

  const updatePassword = () => {
    if (user.role === "candidate") {
      updateCandidatePass(newPassword);
    }
    if (user.role === "company") {
      updateCompanyPass(newPassword);
    }
    if (user.role === "SA") {
      updateAdminPass(newPassword);
    }
  };

  const onSubmit = (data) => {
    if (data.new_password === data.current_password) {
      setError("new_password", {
        type: "manual",
        message: "New password is the same as current password"
      });
      return;
    }

    if (data.new_password === data.confirm_password) {
      const passwordData = {
        id: user.id,
        newPass: data.new_password,
        oldPass: data.current_password
      };
      setNewPassword(passwordData);
      setConfirmDialog(true);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {updateError.error && (
        <ResponseSnackbar
          message={updateError.message}
          open={updateError.error}
          onClose={() => setUpdateError({ error: false, message: "" })}
          severity={"error"}
        />
      )}
      {updateSuccess.success && (
        <ResponseSnackbar
          message={updateSuccess.message}
          open={updateSuccess.success}
          onClose={() => setUpdateSuccess({ success: false, message: "" })}
          severity={"success"}
        />
      )}
      {/* Confirm Dialog */}
      <CustomDialog
        open={confirmDialog}
        dialogTitle={"Confirm Change Password"}
        onClose={() => setConfirmDialog(false)}
      >
        <DialogContent>
          <Typography>You will be logged out if you continue with this action.</Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "gray" }} onClick={() => setConfirmDialog(false)}>
            Cancel
          </Button>
          <LoadingButton onClick={updatePassword}>Confirm</LoadingButton>
        </DialogActions>
      </CustomDialog>
      {/* Form */}
      <Box display={"flex"} flexDirection={"column"} gap={4}>
        <H1>Change Password</H1>
        <FormPasswordField
          control={control}
          name={"current_password"}
          label={"Current Password"}
          sx={{ maxWidth: "400px" }}
          helperText={"Old password is required (min 8 characters long)"}
        />
        <FormPasswordField
          control={control}
          name={"new_password"}
          label={"New Password"}
          sx={{ maxWidth: "400px" }}
          rules={{
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Password must include an uppercase letter, a lowercase letter, a number, and a special character."
            }
          }}
          helperText={"New password is required (min 8 characters long)"}
        />
        <FormPasswordField
          control={control}
          name={"confirm_password"}
          label={"Confirm Password"}
          sx={{ maxWidth: "400px" }}
          rules={{
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              message:
                "Password must include an uppercase letter, a lowercase letter, a number, and a special character."
            }
          }}
          helperText={"Confirm password is required (min 8 characters long)"}
        />
        <Button variant="contained" sx={{ width: 140 }} type="submit">
          Confirm
        </Button>
      </Box>
    </form>
  );
}

export default ChangePassword;
