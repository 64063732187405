import { createContext, useEffect, useReducer } from "react";
import axios from "axios";
import useAuth from "app/hooks/useAuth";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_NOTIFICATIONS": {
      return { ...state, notifications: action.payload };
    }

    case "DELETE_NOTIFICATION": {
      return { ...state, notifications: action.payload };
    }

    case "CLEAR_NOTIFICATIONS": {
      return { ...state, notifications: [] };
    }

    default:
      return state;
  }
};

const NotificationContext = createContext({
  notifications: [],
  deleteNotification: () => {},
  clearNotifications: () => {},
  getNotifications: () => {},
  createNotification: () => {}
});

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, []);
  const { user } = useAuth();
  const deleteNotification = async (notificationID) => {
    try {
      let res;
      if (user.role === "candidate") {
        await axios.delete("/notification", {
          params: { notificationId: notificationID }
        });
        res = await axios.get("/notification", { params: { id: user.id } });
      }

      if (user.role === "company") {
        await axios.delete("/company-notification", {
          params: { notificationId: notificationID }
        });
        res = await axios.get("/company-notification", { params: { id: user.id } });
      }

      if (user.role === "SA") {
        await axios.delete("/admin-notification", { params: { notificationId: notificationID } });
        res = await axios.get("/admin-notification", { params: { id: user.id } });
      }
      dispatch({ type: "DELETE_NOTIFICATION", payload: res.data });
    } catch (e) {
      console.error(e);
    }
  };

  const clearNotifications = async () => {
    try {
      if (user.role === "candidate") {
        await axios.delete("/notification/clear-all", { params: { id: user.id } });
      }

      if (user.role === "company") {
        await axios.delete("/company-notification/clear-all", { params: { id: user.id } });
      }

      if (user.role === "SA") {
        await axios.delete("/admin-notification/clear-all", { params: { id: user.id } });
      }
      dispatch({ type: "CLEAR_NOTIFICATIONS", payload: { notifications: [] } });
    } catch (e) {
      console.error(e);
    }
  };

  const createNotification = async (notification, target) => {
    try {
      let route = "";
      if (target === "company") {
        route = "/company-notification";
      }

      if (target === "admin") {
        route = "/admin-notification";
      }

      if (target === "candidate") {
        route = "/notification";
      }

      await axios.post(route, { ...notification });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const getNotifications = async () => {
      try {
        let res;
        if (user.role === "candidate") {
          res = await axios.get("/notification", { params: { id: user.id } });
        }

        if (user.role === "company") {
          res = await axios.get("/company-notification", { params: { id: user.id } });
        }

        if (user.role === "SA") {
          res = await axios.get("/admin-notification", { params: { id: user.id } });
        }
        dispatch({ type: "LOAD_NOTIFICATIONS", payload: res.data });
      } catch (e) {}
    };

    const getNotificationOnInterval = setInterval(() => {
      getNotifications();
    }, 60000);

    return () => clearInterval(getNotificationOnInterval);
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        deleteNotification,
        clearNotifications,
        createNotification,
        notifications: state.notifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContext;
