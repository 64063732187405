import axios from "axios";

export const sendMail = async (data) => {
  try {
    const res = await axios.post("/send-mail", data);
    return res;
  } catch (e) {
    return "Error sending message";
  }
};
