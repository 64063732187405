import { Fragment } from "react";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { Box, CircularProgress } from "@mui/material";
function TrashWrapper({ responseState, onCloseSnackbar, showProgress, children }) {
  return (
    <Fragment>
      {showProgress && (
        <Box display={"flex"} justifyContent={"center"} width={"100%"} mb={4} alignItems={"center"}>
          <CircularProgress />
        </Box>
      )}
      <ResponseSnackbar
        open={responseState.success}
        onClose={onCloseSnackbar}
        severity={"success"}
        message={responseState.message}
      />
      <ResponseSnackbar
        open={responseState.error}
        onClose={onCloseSnackbar}
        severity={"error"}
        message={responseState.message}
      />
      {children}
    </Fragment>
  );
}

export default TrashWrapper;
