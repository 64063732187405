import React from "react";
import AddCandidateForm from "./shared/AddCandidateForm";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { useState } from "react";
import useAddCandidate from "app/hooks/candidate/useAddCandidate";
function AddCandidate(props) {
  const [alertState, setAlertState] = useState({
    success: false,
    error: false,
    errorMsg: "Add candidate failed! Please try again later."
  });

  const handleAlertClose = () => {
    if (alertState.success) {
      setAlertState((prevState) => ({ ...prevState, success: false }));
    } else if (alertState.error) {
      setAlertState((prevState) => ({ ...prevState, error: false }));
    }
  };

  const showSuccessAlert = () => setAlertState((prevState) => ({ ...prevState, success: true }));

  const showErrorAlert = (error, variables) => {
    setAlertState((prevState) => ({
      ...prevState,
      error: true,
      errorMsg: `Adding candidate failed: ${error.message}`
    }));
  };

  const {
    mutate: mutateCandidate,
    isSuccess,
    isError,
    isPending: isAdding
  } = useAddCandidate(showSuccessAlert, showErrorAlert);

  return (
    <div>
      {isSuccess && (
        <ResponseSnackbar
          message={"Candidate created successfully"}
          open={alertState.success}
          onClose={handleAlertClose}
          variant={"filled"}
          severity={"success"}
          hideDuration={4000}
        />
      )}
      {isError && (
        <ResponseSnackbar
          message={alertState.errorMsg}
          open={alertState.error}
          onClose={handleAlertClose}
          variant={"filled"}
          severity={"error"}
          hideDuration={4000}
        />
      )}
      <AddCandidateForm mutationFn={mutateCandidate} mutationState={isAdding} />
    </div>
  );
}

export default AddCandidate;
