import React from "react";
import { IconButton } from "@mui/material";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
function SortButton({ sortOrder, onSort, identifier }) {
  const handleClick = (event) => {
    onSort(identifier);
  };
  return (
    <IconButton aria-label="Sort" onClick={handleClick}>
      {sortOrder === "ASC" ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />}
    </IconButton>
  );
}

export default SortButton;
