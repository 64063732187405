import { useState } from "react";
import ContentBox from "../jobs/shared/ContentBox";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box } from "@mui/material";
import DeletedJobs from "./DeletedJobs";
import DeletedCompanies from "./DeletedCompanies";
import DeletedCandidates from "./DeletedCandidates";
import useAuth from "app/hooks/useAuth";
function TrashList(props) {
  const [value, setValue] = useState("1");
  const { user } = useAuth();
  const isAdminRole = user.role === "SA";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ContentBox>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Deleted Jobs" value="1" />
            {isAdminRole && <Tab label="Deleted Candidates" value="2" />}
            {isAdminRole && <Tab label="Deleted Companies" value="3" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <DeletedJobs />
        </TabPanel>
        {isAdminRole && (
          <TabPanel value="2">
            <DeletedCandidates />
          </TabPanel>
        )}
        {isAdminRole && (
          <TabPanel value="3">
            <DeletedCompanies />
          </TabPanel>
        )}
      </TabContext>
    </ContentBox>
  );
}

export default TrashList;
