import { Navigate, useLocation, useParams } from "react-router-dom";
// HOOK
import useAuth from "app/hooks/useAuth";

export default function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { role } = useParams();

  if (isAuthenticated) return <>{children}</>;

  let target = "";

  if (role === "sa" || pathname.includes("admin")) {
    target = "/session/login/admin";
  } else if (role === "company" || pathname.includes("company")) {
    target = "/session/login/company";
  } else {
    target = "/session/login/candidate";
  }

  return <Navigate replace to={target} state={{ from: pathname }} />;
}
