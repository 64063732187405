import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete, TextField, styled } from "@mui/material";
import { useState } from "react";

const AutoComplete = styled(Autocomplete)(() => ({ width: 300, marginBottom: "16px" }));
function FormAutocomplete({
  name,
  label,
  values,
  defaultValue,
  control,
  helperText,
  filterOptions,
  sxValues,
  freeSolo = false,
  disabled = false,
  autoSelect = false,
  inputProps,
  ...rest
}) {
  let preloadedValue;
  if (defaultValue) {
    preloadedValue = values.find((value) => value.label === defaultValue);
  }
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={preloadedValue}
      rules={{ required: helperText }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <AutoComplete
          defaultValue={preloadedValue}
          isOptionEqualToValue={(value) => value.label}
          freeSolo={freeSolo}
          autoSelect={autoSelect}
          disabled={disabled}
          filterOptions={filterOptions}
          onChange={(event, value) => {
            onChange(value);
          }}
          sx={{ ...sxValues }}
          options={!values ? [{ label: "Loading...", id: 0 }] : values}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              label={label}
              InputProps={{
                ...params.InputProps,
                ...inputProps
              }}
              variant="outlined"
            />
          )}
        />
      )}
    />
  );
}

export default FormAutocomplete;
