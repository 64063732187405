import { useMutation } from "@tanstack/react-query";
import { postApplication } from "app/api/application_api";

export const usePostApplication = ({ onSuccess, onApply }) => {
  return useMutation({
    mutationFn: (params) => {
      onApply();
      return postApplication(params.data);
    },
    onSuccess: (data) => onSuccess(data)
  });
};
