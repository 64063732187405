import axios from "axios";
export async function login(email, password, type) {
  const response = await axios.post("/login/", { email: email, password: password, type: type });
  localStorage.setItem("token", response.data.token);
  return response.data;
}

export async function registerUser(user, type) {
  const params = { type: type };
  const response = await axios.post("/register", user, { params: params });
  return response.data;
}
