import React from "react";
import { Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Paragraph } from "app/components/Typography";
function StackChip({ direction, spacing, missingMsg = "Not Specified", data, ...rest }) {
  return (
    <Stack direction={direction} spacing={spacing} {...rest}>
      {data.length > 0 &&
        data.map((item, index) => (
          <Chip key={`${item}_${index}`} label={item} variant="outlined" color="success" />
        ))}

      {data && data.length === 0 && <Paragraph>{missingMsg}</Paragraph>}
    </Stack>
  );
}

export default StackChip;
