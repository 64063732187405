import { useMutation } from "@tanstack/react-query";
import { addCompany } from "app/api/company_api";

export const useAddCompany = ({ onSuccess, onError, onSettled }) => {
  return useMutation({
    mutationFn: (params) => addCompany(params.company),
    onSuccess: (data) => onSuccess(),
    onError: (error) => onError(error)
  });
};
