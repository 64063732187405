import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Avatar, Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { convertISODateTimeToDate } from "app/utils/utils";
import ReadMoreText from "app/components/ReadMoreText";
import useGetCompanyLogo from "app/hooks/company/useGetCompanyLogo";
import { convertToBase64 } from "app/utils/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));

const Pebble = styled(Box)(({ theme }) => ({
  padding: "8px 16px 8px 16px",
  border: "1px solid lightgray",
  borderRadius: "25px",
  fontSize: ".9em",
  fontWeight: 500
}));

const DateText = styled(Typography)(() => ({
  fontWeight: 500,
  color: "darkslategray"
}));

function getUniqueBenefits(benefits) {
  const uniqueBenefits = new Set(benefits.map((benefit) => benefit.jp_benefit));
  return [...uniqueBenefits];
}

function JobDetailDialog({ open, handleClose, jobPosting }) {
  const JobBenefits = getUniqueBenefits(jobPosting.Benefits).slice(0, 3);
  const moreBenefitsText = `+${jobPosting.Benefits.length - 3} more`;
  const states = jobPosting.jp_state ? jobPosting.jp_state.split(",") : [];
  const { data: logo } = useGetCompanyLogo({ companyId: jobPosting.company.jp_id });

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"md"}
      fullWidth
    >
      {/** Dialog Heading */}
      <Box sx={{ m: 0, p: 2 }} display={"flex"}>
        <Avatar
          src={`data:image/jpeg;base64,${convertToBase64(logo?.jp_logo?.data)}`}
          sx={{ width: "80px", height: "80px" }}
        />
        {/** Job Title and Company Name*/}
        <Box>
          <Box display={"flex"} gap={1} alignItems={"center"}>
            <Typography variant="h4">{jobPosting.jp_title}</Typography>
            {jobPosting.jp_is_deleted === 1 && (
              <Typography variant="h6" sx={{ color: "red" }}>
                (Deleted Job)
              </Typography>
            )}
          </Box>
          <Typography variant="subtitle1" fontSize={"1.2em"} color={"darkgray"}>
            {jobPosting.company.jp_company_name}
          </Typography>

          {/** Start Date - End Date*/}
          <Box display="flex" gap={2} alignItems={"center"}>
            <DateText>Posted On: {convertISODateTimeToDate(jobPosting.jp_start_date)}</DateText>
            <Box sx={{ width: "10px", height: "2px", background: "black" }}></Box>
            <DateText>Apply By: {convertISODateTimeToDate(jobPosting.jp_end_date)}</DateText>
          </Box>
        </Box>
      </Box>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        {/** Quick Information: Type, Location, Exp, Qualification */}
        <Box display={"flex"} gap={2} mb={2}>
          <Pebble>{jobPosting.jp_type}</Pebble>
          <Pebble>{jobPosting.jp_location}</Pebble>
          <Pebble>{jobPosting.jp_experience}</Pebble>
          <Pebble>{jobPosting.jp_qualification}</Pebble>
        </Box>

        {/** Description */}
        <Box mb={2}>
          <Typography gutterBottom fontWeight={"bold"} variant="h6">
            Description
          </Typography>
          <ReadMoreText text={jobPosting.jp_description} maxChars={600} color={"gray"} />
        </Box>

        {/** Location */}
        <Box mb={2}>
          <Typography gutterBottom fontWeight={"bold"} variant="h6">
            Location
          </Typography>
          <Box display={"flex"} gap={2}>
            {states.length > 0 ? (
              states.map((state) => <Pebble>{state}</Pebble>)
            ) : (
              <Typography>No location specified</Typography>
            )}
          </Box>
        </Box>

        <Box mb={4}>
          <Typography gutterBottom variant="h6" fontWeight={"bold"}>
            Benefits Provided
          </Typography>
          <Grid container display={"flex"} gap={2} alignItems={"center"}>
            {JobBenefits.length === 0 && "No benefits specified"}
            {JobBenefits.length > 0 &&
              JobBenefits.map((benefit) => (
                <Pebble
                  key={benefit}
                  sx={{
                    textAlign: "center"
                  }}
                >
                  <Typography noWrap>{benefit}</Typography>
                </Pebble>
              ))}
            {jobPosting.Benefits.length > 4 && (
              <Typography textAlign={"center"}>{moreBenefitsText}</Typography>
            )}
          </Grid>
        </Box>
        {/** Salary */}
        <Box>
          <Box display={"flex"} gap={1}>
            <Typography variant="h4" color={"green"} fontWeight={"bold"}>
              Rs. {Number(jobPosting.jp_minimum_salary).toLocaleString("en-IN")}/year
            </Typography>
            <Typography variant="h4" color={"green"}>
              {" "}
              -{" "}
            </Typography>
            <Typography variant="h4" color={"green"} fontWeight={"bold"}>
              Rs. {Number(jobPosting.jp_maximum_salary).toLocaleString("en-IN")}/year
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default JobDetailDialog;
