export const EmploymentTypes = [
  { id: 1, label: "Full-Time" },
  { id: 2, label: "Part-Time" },
  { id: 3, label: "Intern" },
  { id: 4, label: "Contract" },
  { id: 5, label: "Temporary" }
];

export const QualificationOptions = [
  { id: 1, label: "High School Diploma" },
  { id: 2, label: "Bachelor's Degree" },
  { id: 3, label: "Master's Degree" },
  { id: 4, label: "PhD" }
];

export const LanguageRequirementOptions = [
  { id: 1, label: "Hindi" },
  { id: 2, label: "English" }
];

export const PayOptions = [
  { value: "Paid", label: "Paid" },
  { value: "Unpaid", label: "Unpaid" }
];

export const ExperienceLevelOptions = [
  { id: 1, label: "Fresher/Entry-Level" },
  { id: 2, label: "Intermediate" },
  { id: 3, label: "Mid-Level" },
  { id: 4, label: "Senior" }
];

export const LocationOptions = [
  { id: 1, label: "Onsite" },
  { id: 2, label: "Remote/WFH" },
  { id: 3, label: "Hybrid" }
];

export const JobBenefits = [
  { id: 0, label: "None" },
  { id: 1, label: "Health Insurance" },
  { id: 2, label: "Retirement Plans" },
  { id: 3, label: "Paid Time Off" },
  { id: 4, label: "Flexible Work Hours" },
  { id: 5, label: "Professional Development" },
  { id: 6, label: "Parental Leave" },
  { id: 7, label: "Life and Disability Insurance" },
  { id: 8, label: "Employee Assistance Programs" },
  { id: 9, label: "Wellness Programs" },
  { id: 10, label: "Stock Options or Equity" },
  { id: 11, label: "Tuition Reimbursement" },
  { id: 12, label: "Employee Discounts" },
  { id: 13, label: "Transportation Benefits" },
  { id: 14, label: "Relocation Assistance" },
  { id: 15, label: "Employee Recognition Programs" },
  { id: 16, label: "Community Involvement" },
  { id: 17, label: "Legal Assistance" },
  { id: 18, label: "Flexible Spending Accounts (FSAs) or Health Savings Accounts (HSAs)" },
  { id: 19, label: "Employee Referral Programs" },
  { id: 20, label: "Maternity and Paternity Support" }
];
