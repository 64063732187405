import React from "react";
import ContentBox from "../jobs/shared/ContentBox";
import useAuth from "app/hooks/useAuth";
import CompanyProfile from "./shared/CompanyProfile";
import CandidateProfile from "./shared/CandidateProfile";
import { useState } from "react";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import AdminProfile from "./shared/AdminProfile";
import { useLocation } from "react-router-dom";

function Profile(props) {
  const { user } = useAuth();
  const { state } = useLocation();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tab, setTab] = useState(state ? state.tab : "1");

  const handleTabChange = (event, value) => setTab(value);

  const handleError = (value) => setError(value);

  const handleSuccess = (value) => setSuccess(value);

  return (
    <ContentBox>
      {success && (
        <ResponseSnackbar
          message={"Updated profile successfully"}
          open={success}
          onClose={() => setSuccess(false)}
          variant={"filled"}
          severity={"success"}
          hideDuration={4000}
        />
      )}
      {error && (
        <ResponseSnackbar
          message={"Update profile failed! Please try again later."}
          open={error}
          onClose={() => setError(false)}
          variant={"filled"}
          severity={"error"}
          hideDuration={4000}
        />
      )}
      {user.role === "company" && <CompanyProfile tab={tab} changeTab={handleTabChange} />}
      {user.role === "candidate" && (
        <CandidateProfile
          tab={tab}
          changeTab={handleTabChange}
          handleSuccess={handleSuccess}
          handleError={handleError}
        />
      )}
      {user.role === "SA" && <AdminProfile />}
    </ContentBox>
  );
}

export default Profile;
