import { Box, TextField, InputAdornment, Button } from "@mui/material";
import React from "react";

import { Search } from "@mui/icons-material";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
function SearchBox({ label, onChange }) {
  const debounced = useDebouncedCallback((value) => {
    onChange(value);
  }, 500);
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={2}>
      <TextField
        label={label}
        size="small"
        onChange={(event) => {
          debounced(event.target.value.trim());
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default SearchBox;
