import React from "react";
import { Box, Button } from "@mui/material";
import FormTextField from "app/components/form-components/FormTextField";
import { useForm } from "react-hook-form";
import ContentBox from "app/views/jobs/shared/ContentBox";
import { AttachEmail, Send } from "@mui/icons-material";
import { useMutation } from "@tanstack/react-query";
import { sendMail } from "app/api/send_mail";
import { useState } from "react";
function SendMailForm({ to, onSent }) {
  const { control, handleSubmit, register } = useForm();
  const [files, setFiles] = useState();
  const { mutate: send } = useMutation({
    mutationFn: (params) => sendMail(params.data)
  });
  const onSubmitData = (data, event) => {
    const fd = new FormData(event.target);
    fd.set("to", to);
    fd.set("from", "");
    const converTextToHTML =
      "<p>" +
      fd
        .get("message")
        .replace(/\n{2,}/g, "</p><p>")
        .replace(/\n/g, "<br>") +
      "</p>";
    fd.set("message", converTextToHTML);
    send({ data: fd });
    onSent();
  };
  const handleFileUpload = (files) => {
    setFiles(files);
  };
  return (
    <ContentBox>
      <form onSubmit={handleSubmit(onSubmitData)} encType="mulitpart/form-data">
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <FormTextField
            control={control}
            name={"to"}
            label={"To:"}
            defaultValue={to}
            disabled={true}
            helperText={"Recipient email is required"}
          />
          <Box display={"flex"} gap={2}>
            <FormTextField
              control={control}
              name={"subject"}
              label={"Subject:"}
              fullWidth={true}
              helperText={"Subject is required"}
            />
            <Button
              variant="outlined"
              style={{ color: "gray", borderColor: "gray", width: 200 }}
              onClick={() => document.querySelector("#file_upload_attachment").click()}
            >
              <input
                type="file"
                name={"attachments"}
                id={`file_upload_attachment`}
                {...register("attachments")}
                accept={
                  ".txt,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                }
                style={{ display: "none" }}
                onChange={({ target: { files } }) => {
                  handleFileUpload(files);
                }}
              />
              <AttachEmail />
            </Button>
            {files && <p>{files[0].name}</p>}
          </Box>
          <FormTextField
            control={control}
            name={"message"}
            label={"Body:"}
            multiline
            rows={24}
            helperText={"Body is required"}
          />
          <Button
            variant={"contained"}
            type="submit"
            endIcon={<Send />}
            sx={{ maxWidth: 200, fontSize: 18 }}
            size="large"
          >
            Send
          </Button>
        </Box>
      </form>
    </ContentBox>
  );
}

export default SendMailForm;
