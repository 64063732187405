import isAbsoluteUrl from "is-absolute-url";

export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const JobTableHeadersForHR = [
  "Company",
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "View",
  "Edit",
  "Delete"
];

export const JobTableHeadersForCandidate = [
  "Company",
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "View",
  "Apply"
];

export const JobTableHeadersForCompany = [
  "Title",
  "Posted On",
  "Type",
  "Experience",
  "View",
  "Edit",
  "Delete"
];

export const DeletedJobHeaders = ["Title", "Posted On", "Type", "Experience", "Restore"];
export const CompanyTableHeaders = {
  Company: "jp_company_name",
  State: "jp_state",
  Country: "jp_country",
  Industry: "jp_industry",
  Email: "jp_email"
};
export const CandidateTableHeaders = {
  Name: "jp_name",
  "Contact No": "jp_contactno",
  IsActive: "jp_active",
  State: "jp_state",
  View: "View",
  Edit: "Edit",
  Delete: "Delete"
};
export const StatusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Shortlisted", value: "shortlisted" },
  { label: "Accepted", value: "accepted" },
  { label: "Rejected", value: "rejected" }
];
export function getTodaysDate() {
  const date = new Date();
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // January is 0!
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const isValidURL = (url) => {
  if (url) {
    const isValid = isAbsoluteUrl(`https://${url}`);
    return isValid || "Please enter valid url";
  }
  return true;
};

export const NameRegex = /^[A-Za-z]+(?:[ '-][A-Za-z]+)*$/;
export const EmailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const PhoneRegex = /^\d{10}$/;
export const JobTitleRegex = /^(?![\d\W_]+$)(?!.*[^\w\s.#])[\w\s.#-]+$/;
export const StateRegex = /^([a-zA-Z]+(-[a-zA-Z]+)?)(?:,\s*[a-zA-Z]+(-[a-zA-Z]+)?)*$/;

export const validateDate = (date) => {
  if (isNaN(date)) {
    return "Invalid date";
  }

  return true;
};
export const filterAlphabets = (event) => {
  const value = event.target.value;
  event.target.value = value.replace(/[^0-9]/g, "");
};

export const convertPDFBlobToPDF = (blobData) => {};
