import { Avatar, Backdrop } from "@mui/material";

const ProfilePreviewDialog = ({ open, profile, onClose }) => {
  return (
    <Backdrop
      open={open}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      onClick={onClose}
    >
      <Avatar src={profile} sx={{ width: "200px", height: "200px" }} />
    </Backdrop>
  );
};

export default ProfilePreviewDialog;
