import axios from "axios";

export const getAllSkills = async () => {
  const skills = await axios.get("/skills/all-skills");
  return skills.data;
};

export const addSkills = async (skills) => {
  return await axios.post("/skills/add-skills", skills);
};
