import { List, ListItem } from "@mui/material";
function BulletList({ items }) {
  return (
    <List
      sx={{
        listStyleType: "disc",
        "& .MuiListItem-root": {
          display: "list-item"
        },
        pl: 4
      }}
    >
      {items.map((item) => (
        <ListItem>{item}</ListItem>
      ))}
    </List>
  );
}

export default BulletList;
