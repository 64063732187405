import React from "react";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
function FormDatePicker({
  label,
  control,
  name,
  rules,
  helperText,
  fullWidth,
  defaultValue,
  handleOnChange = () => {},
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={dayjs(defaultValue)}
      rules={{ required: helperText, ...rules }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            fullWidth
            defaultValue={dayjs(defaultValue)}
            slotProps={{
              textField: {
                fullWidth: fullWidth,
                error: !!error,
                helperText: error ? helperText : null
              }
            }}
            label={label}
            format="DD/MM/YYYY"
            onChange={(event) => {
              handleOnChange(event);
              field.onChange(event.$d);
            }}
            {...rest}
          />
        </LocalizationProvider>
      )}
    />
  );
}

export default FormDatePicker;
