import { Box, Button } from "@mui/material";
import { H1 } from "app/components/Typography";
import FormTextField from "app/components/form-components/FormTextField";
import React from "react";
import { Fragment } from "react";
import { useForm } from "react-hook-form";

function AddAdminForm({ preloadedData, mutationFn, title }) {
  const { control, handleSubmit } = useForm();
  const onSubmit = (data) => {
    mutationFn({ admin: { ...data } });
  };
  return (
    <Fragment>
      <H1>{title}</H1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display={"flex"} gap={2} sx={{ marginY: 4 }}>
          <FormTextField
            defaultValue={preloadedData ? preloadedData.jp_name : ""}
            name="jp_name"
            label={"Full Name *"}
            helperText={"Full name is required"}
            control={control}
          />
          <FormTextField
            defaultValue={preloadedData ? preloadedData.jp_username : ""}
            name="jp_username"
            label={"Username *"}
            helperText={"Username is required"}
            control={control}
          />
        </Box>
        <Box display={"flex"} gap={2} flexDirection={"column"} sx={{ maxWidth: 420 }}>
          <FormTextField
            defaultValue={preloadedData ? preloadedData.jp_email : ""}
            name="jp_email"
            label={"Email *"}
            helperText={"Email is required"}
            control={control}
          />

          <Button variant={"contained"} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Fragment>
  );
}

export default AddAdminForm;
