import { useMutation } from "@tanstack/react-query";
import { verifyOTP } from "app/api/otp_api";

export const useVerifyOTP = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) => verifyOTP({ email: params.email, otp: params.otp }),
    onSuccess: onSuccess,
    onError: onError
  });
};
