import useAuth from "app/hooks/useAuth";
import { useMemo } from "react";
export const useExtractJobData = (jobData, appliedJobs) => {
  const { user } = useAuth();
  return useMemo(() => {
    let extractedData;
    if (user.role === "SA") {
      // Extracting data points for Admin and Candidate
      extractedData = jobData.map(
        ({ jp_id, jp_company_name, jp_title, jp_created_at, jp_type, jp_experience }) => ({
          jp_id,
          jp_company_name,
          jp_title,
          jp_created_at,
          jp_type,
          jp_experience
        })
      );
    } else if (user.role === "company") {
      // Extracting data points for company
      extractedData = jobData.map(({ jp_id, jp_title, jp_created_at, jp_type, jp_experience }) => ({
        jp_id,
        jp_title,
        jp_created_at,
        jp_type,
        jp_experience
      }));
    } else if (user.role === "candidate") {
      extractedData = jobData.map(
        ({ jp_id, jp_company_name, jp_title, jp_created_at, jp_type, jp_experience }) => ({
          jp_id,
          jp_company_name,
          jp_title,
          jp_created_at,
          jp_type,
          jp_experience,
          jp_is_applied: false
        })
      );
      // Marking those jobs that the candidate has already applied for
      extractedData = extractedData.map((job) => {
        if (appliedJobs.find((apply) => job.jp_id === apply.jp_jobpost_id)) {
          return { ...job, jp_is_applied: true };
        } else {
          return job;
        }
      });
    }
    return extractedData;
  }, [user, jobData, appliedJobs]);
};
