import { useQuery } from "@tanstack/react-query";
import { getCompanyList } from "app/api/company_api";

export const useGetCompanies = ({
  sortOrder,
  column,
  limit,
  offset,
  searchTerm,
  refetch = [],
  isEnable = true
}) => {
  return useQuery({
    queryKey: ["companies", column, offset, limit, sortOrder, searchTerm, ...refetch],
    queryFn: () => getCompanyList(sortOrder, column, limit, offset, searchTerm),
    isEnable: isEnable
  });
};
