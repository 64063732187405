import React from "react";
import PaginationTable from "../material-kit/tables/PaginationTable";
function DeletedListWrapper({
  data,
  count,
  tableHeaders,
  actions,
  columnSort,
  columnFilters,
  handleChangeRowsPerPage,
  handleChangePage,
  handleSortOrder
}) {
  return (
    <PaginationTable
      data={data}
      count={count}
      columnHeaders={tableHeaders}
      columnSort={columnSort}
      rowsPerPage={columnFilters.limit}
      currentPage={columnFilters.currentPage}
      sortOrder={columnFilters.sortOrder}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
      handleSortOrder={() => {}}
      actions={actions}
    />
  );
}

export default DeletedListWrapper;
