import axios from "axios";

export async function getAdmin(id) {
  try {
    const response = await axios.get("/admin", { params: { id } });
    const imgBlob = response.data.jp_photo;
    return response.data;
  } catch (e) {
    return e;
  }
}

export async function updateAdmin(admin, id) {
  try {
    const response = await axios.put("/admin/" + id, admin, {
      headers: { "Content-Type": "multipart/form-data" }
    });
    return response;
  } catch (e) {
    return e;
  }
}

export async function updatePassword({ oldPass, newPass, id, reset }) {
  try {
    const response = await axios.patch(
      "/admin/change-password",
      {
        id,
        newPass,
        oldPass,
        reset
      },
      { "Content-Type": "application/json" }
    );
    return response;
  } catch (e) {
    return e;
  }
}

export async function getAdminId(email) {
  try {
    const response = await axios.get("/admin/get-id-by-email", { params: { email } });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
