import React from "react";
import { Box, Typography } from "@mui/material";
function IconText({ icon, content, sx }) {
  return (
    <Box display={"flex"} gap={1} sx={sx} alignItems={"start"} justifyContent={"start"}>
      {icon}
      <Typography variant="body1" sx={{ wordBreak: "break-word" }}>
        {content}
      </Typography>
    </Box>
  );
}

export default IconText;
