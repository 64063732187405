import { Formik } from "formik";
import { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  FormHelperText,
  Card,
  Checkbox,
  Grid,
  TextField,
  Button,
  useTheme,
  Box,
  styled
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import TCDialog from "./shared/TCDialog";
import PrivacyPolicyDialog from "./shared/PrivacyPolicyDialog";
import posting_photo from "../../../assets/illustrations/posting_photo.svg";
import useAuth from "app/hooks/useAuth";
import { Paragraph } from "app/components/Typography";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useContext } from "react";
import { ErrorContext } from "app/contexts/ErrorContext";
import VerifyOTPDialog from "./shared/VerifyOTPDialog";
import { useGenerateOTP } from "app/hooks/otp/useGenerateOTP";
import { useVerifyOTP } from "app/hooks/otp/useVerifyOTP";
import useCheckCompanyExists from "app/hooks/company/useCheckCompanyExists";
// STYLED COMPONENTS
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center"
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center"
}));

const ContentBox = styled(JustifyBox)(() => ({
  height: "100%",
  padding: "32px",
  background: "rgba(0, 0, 0, 0.01)"
}));

const DialogButton = styled(Button)(() => ({ fontSize: "12px", fontWeight: "bold" }));

const JWTRegister = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    minHeight: 400,
    margin: "1rem",
    display: "flex",
    borderRadius: 12,
    alignItems: "center"
  }
}));

// initial login credentials
const initialValues = {
  jp_email: "",
  jp_password: "",
  jp_company_name: "",
  remember: false
};

// form field validation schema
const validationSchema = Yup.object().shape({
  jp_password: Yup.string()
    .min(8, "Password must be 8 character length")
    .matches(/^(?=.*[!@#$%^&*])/, "Password must contain at least 1 special character (!@#$%^&*)")
    .required("Password is required!"),
  remember: Yup.boolean()
    .oneOf([true], "Please accept Terms and Conditions before proceeding")
    .required("Please accept Terms and Conditions before proceeding"),
  jp_email: Yup.string().email("Invalid Email address").required("Email is required!"),
  jp_company_name: Yup.string().required("Company name is required")
});

export default function JwtRegisterCompany() {
  const theme = useTheme();
  const location = useLocation();
  const { register } = useAuth();
  const navigate = useNavigate();
  const { success, error, message, severity, setErrorState, setSuccessState, clearErrorState } =
    useContext(ErrorContext);

  const [otp, setOTP] = useState();
  const [otpError, setOTPError] = useState(false);

  const [registerValues, setRegisterValues] = useState();

  const [loading, setLoading] = useState(false);
  // constrols dialog that displays the terms and conditions
  const [showTC, setShowTC] = useState(false);
  // controls dialog that displays the privacy policy
  const [showPP, setShowPP] = useState(false);
  // controls otp dialog
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);

  // Register Company
  const registerCompany = async (values) => {
    setOTPError(false);
    setLoading(true);
    try {
      const registerState = await register(values, "company");
      if (registerState && registerState.error) {
        setErrorState(true, registerState.message, "error");
      }
      if (registerState && registerState.error === false) {
        setSuccessState(true, "Registration successfull. Redirecting.....");
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    } finally {
      setShowVerifyOTP(false);
    }
  };

  /* OTP Generation */
  const onOTPGenerated = () => {
    setShowVerifyOTP(true);
  };

  const { mutate: generatedOTP, isPending: isGeneratingOTP } = useGenerateOTP(onOTPGenerated);

  const onCompanyCheckSuccess = (data, _) => {
    if (data) {
      setErrorState(true, "Company already exists", "error");
    } else {
      generatedOTP({
        email: registerValues.jp_email,
        type: "verify-email",
        name: registerValues.jp_company_name
      });
    }
  };

  const onCompanyCheckFail = () => {
    setErrorState(true, "Cannot register at the moment", "error");
  };

  const { mutate: checkCompanyExists } = useCheckCompanyExists({
    onSuccess: onCompanyCheckSuccess,
    onError: onCompanyCheckFail
  });

  const { mutate: verifyOTP } = useVerifyOTP({
    onSuccess: () => registerCompany(registerValues),
    onError: () => setOTPError(true)
  });

  const handleFormSubmit = (values) => {
    setRegisterValues(values);
    checkCompanyExists({ email: values.jp_email });
  };

  const loginLink = location.pathname.includes("company")
    ? "/session/login/company"
    : "/session/login/candidate";

  return (
    <JWTRegister>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error || success}
        onClose={() => {
          if (success) {
            navigate("/session/login/company");
          }
          clearErrorState();
        }}
        autoHideDuration={3000}
        color={severity}
      >
        <Alert severity={severity}>{message}</Alert>
      </Snackbar>
      {/** Terms and Condition */}
      <TCDialog open={showTC} handleClose={() => setShowTC(false)} />
      {/** Privacy Policy*/}
      <PrivacyPolicyDialog open={showPP} handleClose={() => setShowPP(false)} />
      {/** Verify OTP */}
      <VerifyOTPDialog
        open={showVerifyOTP}
        otpError={otpError}
        verifyState={loading}
        resendState={isGeneratingOTP}
        handleOTPChange={(otp) => setOTP(otp)}
        handleVerifyOTP={() => verifyOTP({ email: registerValues.jp_email, otp: otp })}
        handleResendOTP={() =>
          generatedOTP({
            email: registerValues.jp_email,
            type: "verify-email",
            name: registerValues.jp_company_name
          })
        }
        handleClose={() => setShowVerifyOTP(false)}
      />
      <Card className="card">
        <Grid container>
          <Grid item sm={6} xs={12}>
            <ContentBox>
              <img width="100%" alt="Register" src={posting_photo} />
            </ContentBox>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box p={4} height="100%">
              <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      name="jp_company_name"
                      label="Company name"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.jp_company_name}
                      onChange={handleChange}
                      helperText={touched.jp_company_name && errors.jp_company_name}
                      error={Boolean(errors.jp_company_name && touched.jp_company_name)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      type="email"
                      name="jp_email"
                      label="Email"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.jp_email}
                      onChange={handleChange}
                      helperText={touched.jp_email && errors.jp_email}
                      error={Boolean(errors.jp_email && touched.jp_email)}
                      sx={{ mb: 3 }}
                    />

                    <TextField
                      fullWidth
                      size="small"
                      name="jp_password"
                      type="password"
                      label="Password"
                      variant="outlined"
                      onBlur={handleBlur}
                      value={values.jp_password}
                      onChange={handleChange}
                      helperText={touched.jp_password && errors.jp_password}
                      error={Boolean(errors.jp_password && touched.jp_password)}
                      sx={{ mb: 2 }}
                    />

                    <FlexBox gap={1} alignItems="center">
                      <Checkbox
                        size="small"
                        name="remember"
                        onChange={handleChange}
                        checked={values.remember}
                        sx={{ padding: 0 }}
                      />

                      <Paragraph fontSize={13}>
                        I have read and agree to the terms of service.
                      </Paragraph>
                    </FlexBox>
                    {errors.remember && (
                      <FormHelperText sx={{ color: "red" }}>{errors.remember}</FormHelperText>
                    )}

                    <Box display={"flex"} gap={2} mt={1}>
                      <DialogButton size="small" variant="outlined" onClick={() => setShowTC(true)}>
                        View T&C
                      </DialogButton>
                      <DialogButton size="small" variant="outlined" onClick={() => setShowPP(true)}>
                        View Privacy Policy
                      </DialogButton>
                    </Box>

                    <LoadingButton
                      type="submit"
                      color="primary"
                      loading={isGeneratingOTP && !showVerifyOTP}
                      variant="contained"
                      sx={{ mb: 2, mt: 3 }}
                    >
                      Register
                    </LoadingButton>

                    <Paragraph>
                      Already have an account?
                      <NavLink
                        to={loginLink}
                        style={{ color: theme.palette.primary.main, marginLeft: 5 }}
                      >
                        Login
                      </NavLink>
                    </Paragraph>
                  </form>
                )}
              </Formik>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </JWTRegister>
  );
}
