import { useQuery } from "@tanstack/react-query";
import { getCandidateResume } from "app/api/candidate_api";

export const useDownloadResume = ({ id, fileName }) => {
  return useQuery({
    queryKey: ["GetCandidateResume", id],
    queryFn: async () => {
      const resume = await getCandidateResume(id, fileName);
      const url = window.URL.createObjectURL(new Blob([resume]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return resume;
    },
    enabled: !!id,
    staleTime: Infinity
  });
};
