import { Close } from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import React from "react";
import BootstrapDialog from "./BootstrapDialog";
import { convertISODateTimeToDate } from "app/utils/utils";
import BulletList from "./BulletList";
const Subheading = ({ heading }) => {
  return (
    <Typography gutterBottom fontWeight={"bold"}>
      {heading}
    </Typography>
  );
};
function PrivacyPolicyDialog({ open, handleClose }) {
  const AppName = "Reyan Job Portal";
  const DateToday = convertISODateTimeToDate(new Date());
  return (
    <BootstrapDialog open={open} aria-labelledby="privacy dialog" onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>Privacy Policy ({`Updated on: 16-07-2024`})</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Subheading heading={`Effective Date: ${DateToday}`} />
        <Typography gutterBottom>
          Please read this Privacy Policy carefully before using Reyan Job Portal. If you do not
          agree with the terms of this privacy policy, please do not access the site.
        </Typography>
        <Subheading heading={`Information We Collect`} />
        <Typography gutterBottom>
          We may collect personal information from you when you voluntarily submit information to
          the Site. This can include:{" "}
        </Typography>
        <BulletList
          items={[
            "Contact information such as name, email address, mailing address, and phone number.",
            "Professional information such as employment history, resume, and qualifications.",
            "Usage data and technical information collected automatically through cookies and similar technologies."
          ]}
        />
        <Subheading heading={"How we use your information"} />
        <Typography gutterBottom>
          We use your information to provide services, personalize your experience, improve our
          site, and communicate with you.{" "}
        </Typography>
        <Subheading heading={"Disclose of your information"} />
        <Typography gutterBottom>
          We may disclose your personal information to third parties only in the following
          circumstances:{" "}
        </Typography>
        <BulletList
          items={[
            "With your consent",
            "To comply with legal obligations",
            "To protect and defend our rights and property"
          ]}
        />
        <Subheading heading={"Security"} />
        <Typography gutterBottom>
          We implement reasonable security measures to protect your data, However, no method of
          transmission over the internet or method of electronic storage is completely secure.
        </Typography>
        <Subheading heading={"Your Data Protection Rights:"} />
        <Typography gutterBottom>
          Depending on your location, you may have rights to access, correct, or delete your
          personal data.
        </Typography>
        <Subheading heading={"Changes to this policy: "} />
        <Typography gutterBottom>
          We may update this policy to reflect changes in our practices or for legal reasons. Check
          this page periodically for updates.
        </Typography>
        <Typography gutterBottom textAlign={"center"} mt={2}>
          If you have any questions about this policy, please contact us at{" "}
          <strong>hrconsultancy@reyan.co.in.</strong>
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default PrivacyPolicyDialog;
