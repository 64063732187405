import useAuth from "app/hooks/useAuth";
import { useMemo } from "react";
import {
  JobTableHeadersForCandidate,
  JobTableHeadersForCompany,
  JobTableHeadersForHR
} from "app/utils/constant";
export const useGetJobTableHeaders = () => {
  const { user } = useAuth();
  return useMemo(() => {
    switch (user.role) {
      case "SA":
        return JobTableHeadersForHR;
      case "company":
        return JobTableHeadersForCompany;
      default:
        return JobTableHeadersForCandidate;
    }
  }, [user]);
};
