import { useQuery } from "@tanstack/react-query";
import { getApplicants } from "app/api/application_api";
import useAuth from "../useAuth";

export const useGetApplicants = ({
  sort,
  column,
  limit,
  offset,
  searchQuery,
  post,
  status,
  id
}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ["candidates", sort, column, limit, offset, searchQuery, post, status, id],
    queryFn: () => getApplicants(sort, column, limit, offset, searchQuery, post, status, id),
    enabled: user.role === "SA"
  });
};
