import axios from "axios";

export const generateOTP = async ({ email, type, name }) => {
  try {
    const res = await axios.post("/send-otp", { email, type, name });
    return res.data;
  } catch (error) {
    return { success: false, error: error };
  }
};

export const verifyOTP = async ({ email, otp }) => {
  const res = await axios.post("/verify-otp", { email, otp });
  return res.data;
};
