import { useMutation } from "@tanstack/react-query";
import { addCandidate } from "app/api/candidate_api";
const useAddCandidate = (onSuccess, onAlert) => {
  return useMutation({
    mutationKey: ["add_candidate"],
    mutationFn: (params) => addCandidate(params.data),
    onSuccess: (data) => onSuccess(),
    onError: (error) => onAlert(error)
  });
};

export default useAddCandidate;
