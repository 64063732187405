import axios from "axios";

axios.defaults.baseURL = "https://jobs.reyan.co.in/reyanjobs";
// axios.defaults.baseURL = "http://localhost:5001/reyanjobs";

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};
