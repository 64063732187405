import { useMutation, useQuery } from "@tanstack/react-query";
import { generateOTP } from "app/api/otp_api";

export const useGenerateOTP = (onSuccess) => {
  return useMutation({
    mutationFn: (params) =>
      generateOTP({ email: params.email, type: params.type, name: params.name }),
    onSuccess: onSuccess
  });
};
