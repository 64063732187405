import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Paragraph } from "app/components/Typography";
function DetailAccordian({ title, details }) {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "black" }} />}
        color="gray"
        style={{
          backgroundColor: "lightgray",
          color: "black",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px"
        }}
        aria-controls="Job requirements"
      >
        <Paragraph>{title}</Paragraph>
      </AccordionSummary>
      <AccordionDetails>{details.map((item) => item)}</AccordionDetails>
    </Accordion>
  );
}

export default DetailAccordian;
