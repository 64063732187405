import { useMutation } from "@tanstack/react-query";
import { deleteCandidate } from "app/api/candidate_api";

const useRemoveCandidate = (id, onSuccess, onError) => {
  return useMutation({
    mutationFn: (params) => deleteCandidate(id),
    onSuccess: () => onSuccess(),
    onError: () => onError()
  });
};

export default useRemoveCandidate;
