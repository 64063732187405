import React from "react";
import ContentBox from "./ContentBox";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import { Span } from "app/components/Typography";
import { InputAdornment } from "@mui/material";
import {
  EmploymentTypes,
  ExperienceLevelOptions,
  LocationOptions,
  QualificationOptions
} from "../Constants";
import PayRange from "./PayRange";
import FormTextField from "app/components/form-components/FormTextField";
import { useForm } from "react-hook-form";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import FormAutocompleteMulti from "app/components/form-components/FormAutocompleteMulti";
import { getCurrentTimeStamp, convertListToString } from "app/utils/utils";
import useAuth from "app/hooks/useAuth";
import { States } from "app/views/candidate/shared/Constants";
import { Countries } from "app/utils/selection_constants";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import { useLocation } from "react-router-dom";
import { Add } from "@mui/icons-material";
import { useState } from "react";
import { Fragment } from "react";
import { useAddSkills } from "app/hooks/skill/useAddSkills";
import { useGetSkills } from "app/hooks/skill/useGetSkills";
import AddNewSkillDialog from "./AddNewSkillDialog";
import AddNewBenefitsDialog from "./AddNewBenefitsDialog";
import { useGetBenefits } from "app/hooks/benefit/useGetBenefits";
import { useAddBenefits } from "app/hooks/benefit/useAddBenefits";
import { LoadingButton } from "@mui/lab";
import { useQuery } from "@tanstack/react-query";
import { getCompanyNames } from "app/api/company_api";
import { JobTitleRegex, StateRegex } from "app/utils/constant";
import dayjs from "dayjs";
import { createFilterOptions } from "@mui/material";
const dateToday = dayjs(new Date());
const dateOneMonthAfter = dateToday.add(1, "month");

function PostJobForm({ mutationFn, preloadedData, title = "", loadingState }) {
  const { handleSubmit, control, watch } = useForm();
  const { user } = useAuth();
  const [skill, setSkill] = useState(false); // For add skill dialog
  const [benefit, setBenefit] = useState(false); // For add benefit dialog
  const location = useLocation();

  const startDate = watch("jp_start_date");

  const isEditPath = location.pathname.includes("/jobs/listings") ? true : false;
  const isAdminRole = user.role === "SA";

  //Get list of companies
  const { data: companies } = useQuery({
    queryKey: ["company_name_list"],
    queryFn: () => getCompanyNames(0),
    staleTime: 10000
  });

  // Add new skills
  const { mutate: addSkill, isPending: isAdding, data: newSkills } = useAddSkills();

  // Get skill list
  const { data: skills } = useGetSkills({ keys: [newSkills] });

  // Add new benefits
  const { mutate: addBenefits, data: newBenefits } = useAddBenefits();

  // Get Benefit list
  const { data: benefits } = useGetBenefits({ keys: [newBenefits] });

  // Track min salary to check if max salary > min salary
  const minSalary = watch("jp_minimum_salary", 0);

  function getCompanyIdByName(companyName) {
    let selectedCompany = companies.find((company) => company.jp_company_name === companyName);
    return selectedCompany.jp_id;
  }

  const savedBenefits = preloadedData
    ? preloadedData.Benefits
      ? preloadedData.Benefits.map(({ jp_id, jp_benefit }) => ({
          jp_id,
          label: jp_benefit
        }))
      : []
    : [];

  const onSubmitData = (data) => {
    const JobPost = {
      ...data,
      jp_location: convertListToString(data.jp_location),
      jp_benefits: convertListToString(data.jp_benefits),
      jp_experience: convertListToString(data.jp_experience),
      jp_required_skills: convertListToString(data.jp_required_skills),
      jp_state: data.jp_state,
      jp_country: convertListToString(data.jp_country),
      jp_start_date: data.jp_start_date.toISOString().split("T")[0],
      jp_end_date: data.jp_end_date.toISOString().split("T")[0],
      jp_is_visible: false,
      jp_qualification: data.jp_qualification.label,
      jp_type: data.jp_type.label,
      jp_started_at: getCurrentTimeStamp(),
      jp_company_id: isAdminRole ? getCompanyIdByName(data.jp_company_name.label) : user.id,
      jp_company_name: isAdminRole ? data.jp_company_name.label : user.name,
      jp_role: user.role
    };

    if (location.pathname.includes("/jobs/listings")) {
      JobPost.jp_updated_at = getCurrentTimeStamp();
      JobPost.jp_updated_by = user.id;
    }

    if (location.pathname.includes("/jobs/postjob")) {
      JobPost.jp_created_at = getCurrentTimeStamp();
      JobPost.jp_created_by = user.id;
    }
    mutationFn({ data: JobPost });
  };

  const companyFilterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => {
      return option.label;
    }
  });

  const skillFilterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label
  });

  return (
    <Fragment>
      <AddNewSkillDialog
        open={skill}
        onClose={() => setSkill(false)}
        addFn={addSkill}
        loading={isAdding}
      />
      <AddNewBenefitsDialog
        open={benefit}
        onClose={() => setBenefit(false)}
        addFn={addBenefits}
        loading={false}
      />
      <ContentBox>
        <h1>{title}</h1>
        <form onSubmit={handleSubmit(onSubmitData)} onError={() => null}>
          <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
            <h2>Job Title</h2>
            <Box display={"flex"} gap={3}>
              <FormTextField
                control={control}
                name={"jp_title"}
                defaultValue={preloadedData ? preloadedData.jp_title : ""}
                label={"Job Title *"}
                style={{ flex: 1 }}
                rules={{
                  pattern: { value: JobTitleRegex, message: "Please enter valid job title" }
                }}
                helperText={"Title is required"}
              />
              {/** For Admin role when posting a job we need to show company dropdown */}
              {/** But when updating job details, we disable company dropdown  */}
              {isAdminRole && companies && (
                <FormAutocomplete
                  control={control}
                  name={"jp_company_name"}
                  label={"Company *"}
                  defaultValue={preloadedData ? preloadedData.company.jp_company_name : ""}
                  values={companies.map((company, index) => ({
                    id: index,
                    label: company.jp_company_name
                  }))}
                  filterOptions={companyFilterOptions}
                  disabled={isEditPath ? true : false}
                  helperText={"Company name is required"}
                />
              )}
            </Box>
            <h2>Start and End Date</h2>
            <Box gap={4} display={"flex"}>
              <FormDatePicker
                defaultValue={preloadedData ? preloadedData.jp_start_date : dateToday}
                control={control}
                name={"jp_start_date"}
                minDate={isEditPath ? dayjs(preloadedData.jp_start_date) : dateToday}
                label={"Start Date"}
              />
              <FormDatePicker
                defaultValue={preloadedData ? preloadedData.jp_end_date : dateOneMonthAfter}
                control={control}
                name={"jp_end_date"}
                minDate={
                  isEditPath
                    ? dayjs(preloadedData.jp_start_date)
                    : startDate
                    ? dayjs(startDate)
                    : undefined
                }
                label={"End Date"}
              />
            </Box>
            <h2>Basic Requirements</h2>
            <Box gap={4} display={"flex"} alignItems="start" sx={{ flex: "column" }}>
              <FormAutocomplete
                control={control}
                name={"jp_type"}
                label={"Employment Type *"}
                defaultValue={preloadedData ? preloadedData.jp_type : ""}
                values={EmploymentTypes}
                helperText={"Employment type is required"}
              />
              <FormAutocompleteMulti
                control={control}
                defaultValue={preloadedData ? preloadedData.jp_experience : ""}
                name={"jp_experience"}
                label={"Experience Level *"}
                isOptionEqualToValue={(option, value) => {
                  return option.label === value.label;
                }}
                helperText={"Experience level is required"}
                values={ExperienceLevelOptions}
              />
              <FormAutocomplete
                control={control}
                defaultValue={preloadedData ? preloadedData.jp_qualification : ""}
                name={"jp_qualification"}
                label={"Highest Education Level *"}
                values={QualificationOptions}
                helperText={"Education level is required"}
              />
              <FormTextField
                control={control}
                name={"jp_required_degree"}
                defaultValue={preloadedData ? preloadedData.jp_required_degree : ""}
                label={"Required Degree *"}
                helperText={"Degree is required"}
              />
            </Box>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <h2>Skills Requirements</h2>
              <Button
                variant={"outlined"}
                endIcon={<Add />}
                size="small"
                onClick={() => setSkill(true)}
                style={{ paddingY: 0, maxHeight: "30px" }}
              >
                <Typography variant="body2">Add Skill</Typography>
              </Button>
            </Box>
            {skills && (
              <FormAutocompleteMulti
                name="jp_required_skills"
                control={control}
                label={"Required Skills *"}
                defaultValue={preloadedData ? preloadedData.jp_required_skills : null}
                isOptionEqualToValue={(option, value) => {
                  return option.jp_id === value.jp_id;
                }}
                filterOptions={skillFilterOptions}
                values={skills.map((skill) => ({ jp_id: skill.jp_id, label: skill.jp_skill }))}
                helperText={"Skills is required"}
              />
            )}

            <h2>Payment Information</h2>
            <Box gap={4}>
              <PayRange>
                <FormTextField
                  name="jp_minimum_salary"
                  label="Minimum Pay Range (Yearly) *"
                  defaultValue={preloadedData ? preloadedData.jp_minimum_salary : ""}
                  type="number"
                  helperText="Starting salary is required"
                  control={control}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>
                  }}
                />
                <span>To</span>
                <FormTextField
                  control={control}
                  name="jp_maximum_salary"
                  defaultValue={preloadedData ? preloadedData.jp_maximum_salary : ""}
                  type="number"
                  helperText="Maximum salary is required"
                  label="Maximum Pay Range (Yearly) *"
                  rules={{
                    validate: (value) =>
                      parseInt(value, 10) > parseInt(minSalary, 10) ||
                      "Maximum salary must be greater than minimum salary"
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">₹</InputAdornment>
                  }}
                />
              </PayRange>
            </Box>
            <h2>Location</h2>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormAutocompleteMulti
                  control={control}
                  defaultValue={preloadedData ? preloadedData.jp_location : ""}
                  name={"jp_location"}
                  label={"Location Type *"}
                  helperText={"Location is required"}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  values={LocationOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  name="jp_city"
                  defaultValue={preloadedData ? preloadedData.jp_city : ""}
                  helperText="City is required"
                  label="City (comma separated for multiple cities)*"
                  rules={{ pattern: { value: StateRegex, message: "Invalid city mentioned" } }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormTextField
                  control={control}
                  defaultValue={preloadedData ? preloadedData.jp_state : ""}
                  name={"jp_state"}
                  label={"State (comma separated for multiple states) *"}
                  helperText={"State is required"}
                  rules={{ pattern: { value: StateRegex, message: "Invalid state mentioned" } }}
                  values={States}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormAutocompleteMulti
                  control={control}
                  name={"jp_country"}
                  defaultValue={preloadedData && preloadedData.jp_country}
                  label={"Country (multi-select allowed) *"}
                  isOptionEqualToValue={(option, value) => option.label === value.label}
                  values={Countries}
                  helperText={"Country is required"}
                />
              </Grid>
            </Grid>
            <Box display={"flex"} gap={2} alignItems={"center"}>
              <h2>Benefits</h2>
              <Button
                variant={"outlined"}
                endIcon={<Add />}
                size="small"
                onClick={() => setBenefit(true)}
                style={{ paddingY: 0, maxHeight: "30px" }}
              >
                <Typography variant="body2">Add Benefit</Typography>
              </Button>
            </Box>
            <Box>
              {benefits && (
                <FormAutocompleteMulti
                  control={control}
                  name={"jp_benefits"}
                  defaultValue={savedBenefits}
                  label={"Job Benefits *"}
                  helperText={"Job benefits is required"}
                  isOptionEqualToValue={(option, value) => {
                    return option.jp_id === value.jp_id;
                  }}
                  values={benefits.map((benefit) => ({
                    jp_id: benefit.jp_id,
                    label: benefit.jp_benefit
                  }))}
                />
              )}
            </Box>
            <h2>Provide a Job Description</h2>
            <FormTextField
              name={"jp_description"}
              label={"Add job description *"}
              defaultValue={preloadedData ? preloadedData.jp_description : ""}
              control={control}
              helperText={"Job description is required"}
              multiline
              fullWidth
              rows={10}
              sx={{ mb: 4 }}
            />
          </Grid>

          <LoadingButton color="primary" variant="contained" type="submit" loading={loadingState}>
            <Icon>send</Icon>
            <Span sx={{ pl: 1, textTransform: "capitalize" }}>
              {title.includes("Edit") ? "Update" : "Submit"}
            </Span>
          </LoadingButton>
        </form>
      </ContentBox>
    </Fragment>
  );
}

export default PostJobForm;
