import { getJobListings } from "app/api/job_api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../useAuth";
const useGetJobs = ({
  sortOrder,
  column,
  limit,
  offset,
  searchTerm,
  id,
  type,
  exp,
  skill,
  location,
  ...rest
}) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: [
      "job_listings",
      sortOrder,
      column,
      limit,
      offset,
      searchTerm,
      type,
      exp,
      skill,
      rest,
      location
    ],
    queryFn: () =>
      getJobListings(sortOrder, column, limit, offset, searchTerm, id, type, exp, skill, location),
    enabled: user.role === "SA" || user.role === "candidate"
  });
};

export default useGetJobs;
