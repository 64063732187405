import { Fragment } from "react";
import AddCompanyForm from "./shared/AddCompanyForm";
import { useAddCompany } from "app/hooks/company/useAddCompany";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateCompany } from "app/hooks/company/useUpdateCompany";
import useAuth from "app/hooks/useAuth";
import Loading from "app/components/MatxLoading";
function AddCompany(props) {
  const location = useLocation();
  const [alertState, setAlertState] = useState({
    success: false,
    error: false,
    message: ""
  });
  const { user } = useAuth();
  const {
    mutate: insertCompany,
    isPending: isAdding,
    isSuccess,
    isError
  } = useAddCompany({
    onSuccess: () =>
      setAlertState({ success: true, error: false, message: "Company added successfully" }),
    onError: (error) => {
      setAlertState({
        success: false,
        error: true,
        message: error.response.data.message || "Error adding company"
      });
    }
  });
  const {
    mutate: updateCompany,
    isPending: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError
  } = useUpdateCompany({
    id: user.id,
    onSuccess: () =>
      setAlertState((prevState) => ({
        ...prevState,
        success: true,
        message: "Company details updated successfully"
      })),
    onError: () =>
      setAlertState((prevState) => ({
        ...prevState,
        error: true,
        message: "Error updating company"
      }))
  });
  const handleAlertClose = () => {
    setAlertState({ success: false, error: false, message: "" });
  };

  return (
    <Fragment>
      {isUpdating && <Loading />}
      {(isSuccess || isUpdateSuccess) && (
        <ResponseSnackbar
          message={alertState.message}
          open={alertState.success}
          onClose={handleAlertClose}
          variant={"filled"}
          severity={"success"}
          hideDuration={4000}
        />
      )}
      {(isError || isUpdateError) && (
        <ResponseSnackbar
          message={alertState.message}
          open={alertState.error}
          onClose={handleAlertClose}
          variant={"filled"}
          severity={"error"}
          hideDuration={4000}
        />
      )}
      <AddCompanyForm
        mutationFn={location.pathname.includes("addCompany") ? insertCompany : updateCompany}
        mutationState={location.pathname.includes("addCompany") ? isAdding : isUpdating}
      />
    </Fragment>
  );
}

export default AddCompany;
