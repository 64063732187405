import { useQuery } from "@tanstack/react-query";
import { getCandidateApplications } from "app/api/application_api";
const useGetCandidateApplication = ({ id, debounceSearch, status, enabled }) => {
  return useQuery({
    queryKey: ["CandidateApplication", id, debounceSearch, status],
    queryFn: () =>
      getCandidateApplications(id, debounceSearch, status.map((status) => status.value).join(",")),
    enabled: enabled
  });
};

export default useGetCandidateApplication;
