import { useMutation, useQuery } from "@tanstack/react-query";
import { getDeletedCompanies, restoreCompany } from "app/api/company_api";
import React from "react";
import DeletedListWrapper from "./DeletedListWrapper";
import { useState } from "react";
import { Restore } from "@mui/icons-material";
import TrashWrapper from "./shared/TrashWrapper";
const DeletedCompaniesHeaders = ["Company", "State", "Country", "Industry", "Email", "Restore"];
function DeletedCompanies(props) {
  const [columnFilters, setColumnFilters] = useState({
    sortOrder: "DESC",
    column: undefined,
    limit: 5,
    offset: 0,
    currentPage: 0
  });

  function handleChangeRowsPerPage(event) {
    console.log(+event.target.value);
    setColumnFilters((prev) => ({ ...prev, limit: +event.target.value, currentPage: 0 }));
  }

  const handleChangePage = (_, newPage) => {
    setColumnFilters((prev) => ({ ...prev, currentPage: newPage, offset: newPage * prev.limit }));
  };

  function handleRestoreCompany(selectedRow) {
    restore({ id: selectedRow.jp_id });
  }

  const [responseState, setResponseState] = useState({
    error: false,
    success: false,
    message: ""
  });

  const {
    mutate: restore,
    isPending: isRestoring,
    isSuccess: isRestoreSuccess
  } = useMutation({
    mutationFn: (params) => restoreCompany(params.id),
    onSuccess: () => {
      setResponseState((prev) => ({
        ...prev,
        success: true,
        message: "Company restored successfully"
      }));
    },
    onError: () => {
      setResponseState((prev) => ({ ...prev, error: true, message: "Company restore failed" }));
    }
  });

  const { data: deletedCompanies, isFetched } = useQuery({
    queryKey: [
      "deletedCompanies",
      columnFilters.limit,
      columnFilters.offset,
      columnFilters.currentPage,
      isRestoreSuccess
    ],
    queryFn: () =>
      getDeletedCompanies(
        columnFilters.sortOrder,
        columnFilters.column,
        columnFilters.limit,
        columnFilters.offset
      )
  });

  const content = isFetched ? (
    <DeletedListWrapper
      data={deletedCompanies.rows.map(
        ({ jp_id, jp_company_name, jp_state, jp_country, jp_industry, jp_email }) => ({
          jp_id,
          jp_company_name,
          jp_state,
          jp_country,
          jp_industry,
          jp_email
        })
      )}
      count={deletedCompanies.count}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      tableHeaders={DeletedCompaniesHeaders}
      columnSort={["Company"]}
      columnFilters={columnFilters}
      actions={[{ icon: <Restore />, action: handleRestoreCompany }]}
    />
  ) : (
    <span>Loading...</span>
  );

  const closeResponseSnackBar = () =>
    setResponseState({ error: false, success: false, message: "" });

  return (
    <TrashWrapper
      responseState={responseState}
      showProgress={isRestoring ? true : false}
      onCloseSnackbar={closeResponseSnackBar}
    >
      {content}
    </TrashWrapper>
  );
}

export default DeletedCompanies;
