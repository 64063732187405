import { styled, Dialog } from "@mui/material";
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));
function BootstrapDialog({ open, handleClose, sx, children }) {
  return (
    <CustomDialog open={open} onClose={handleClose} sx={sx}>
      {children}
    </CustomDialog>
  );
}

export default BootstrapDialog;
