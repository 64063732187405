import { Checkbox, FormControl, Grid, FormControlLabel, Box, FormGroup } from "@mui/material";
import { useState } from "react";
function FilterByType({ onChecked }) {
  const [checkedState, setCheckedState] = useState({
    partTime: false,
    fullTime: false,
    intern: false,
    contract: false,
    temporary: false
  });
  const handleCheckedChange = (event) => {
    const { name, checked } = event.target;
    setCheckedState({
      ...checkedState,
      [name]: checked
    });
    let type = "";
    switch (name) {
      case "partTime":
        type = "Part-Time";
        break;
      case "fullTime":
        type = "Full-Time";
        break;
      case "intern":
        type = "Intern";
        break;
      case "contract":
        type = "Contract";
        break;
      case "temporary":
        type = "Temporary";
        break;
    }

    onChecked({ newType: type, checked: checked });
  };

  return (
    <Box>
      <h4>Search By Job Type</h4>
      <FormControl component={"fieldset"}>
        <FormGroup>
          <Grid container maxWidth={"300px"}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.partTime}
                    name={"partTime"}
                    onChange={handleCheckedChange}
                  />
                }
                label={"Part-Time"}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.fullTime}
                    name={"fullTime"}
                    onChange={handleCheckedChange}
                  />
                }
                label={"Full-Time"}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.intern}
                    name={"intern"}
                    onChange={handleCheckedChange}
                  />
                }
                label={"Intern"}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.contract}
                    name={"contract"}
                    onChange={handleCheckedChange}
                  />
                }
                label={"Contract"}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState.temporary}
                    name={"temporary"}
                    onChange={handleCheckedChange}
                  />
                }
                label={"Temporary"}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </FormControl>
    </Box>
  );
}

export default FilterByType;
