import FormTextField from "app/components/form-components/FormTextField";
import ContentBox from "app/views/jobs/shared/ContentBox";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Box, Stack, Button, Typography, Avatar } from "@mui/material";
import FormAutocompleteMulti from "app/components/form-components/FormAutocompleteMulti";
import { QualificationOptions } from "app/views/jobs/Constants";
import { States } from "./Constants";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import { LoadingButton } from "@mui/lab";
import {
  calculateAge,
  convertListToString,
  convertToBase64,
  createObjectURLFromFile,
  getCurrentTimeStamp,
  isFileLimitExceeded
} from "app/utils/utils";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import { Send, UploadFile } from "@mui/icons-material";
import { Languages } from "app/utils/selection_constants";
import useAuth from "app/hooks/useAuth";
import { useLocation } from "react-router-dom";
import Add from "@mui/icons-material/Add";
import AddNewSkillDialog from "app/views/jobs/shared/AddNewSkillDialog";
import { useAddSkills } from "app/hooks/skill/useAddSkills";
import { useGetSkills } from "app/hooks/skill/useGetSkills";
import {
  EmailRegex,
  filterAlphabets,
  NameRegex,
  PhoneRegex,
  validateDate
} from "app/utils/constant";
import UploadFileTitle from "./UploadFileTitle";
import ProfilePreviewDialog from "./ProfilePreviewDialog";
import { useGetCandidateResume } from "app/hooks/candidate/useGetCandidateResume";
function AddCandidateForm({ mutationFn, preloadedData, title = "", mutationState }) {
  const {
    control,
    handleSubmit,
    register,
    setValue,
    setError,
    clearErrors,
    getFieldState,
    getValues,
    formState: { errors }
  } = useForm();
  const { user } = useAuth();
  const location = useLocation();
  const isEditAction =
    location.pathname.includes("candidate/listings") || location.pathname.includes("/profile");

  const [resume, setResume] = useState();
  const [photo, setPhoto] = useState(preloadedData?.jp_photo);
  const previewPhoto =
    photo instanceof File
      ? createObjectURLFromFile(photo)
      : `data:image/jpeg;base64,${convertToBase64(photo?.data)}`;

  const [skill, setSkill] = useState(false); // opens and closes 'Add Skill Dialoga
  const [viewProfile, setViewProfile] = useState(false); // Open and closes view profile picture dialog

  // Skills that were saved in DB
  const preSkills = preloadedData
    ? preloadedData.Skills
      ? preloadedData.Skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill }))
      : []
    : [];
  // Add new skills
  const { mutate: addSkill, isPending: isAdding, data: newSkills } = useAddSkills();

  const { data: dbResume } = useGetCandidateResume({
    id: preloadedData ? preloadedData.jp_id : undefined,
    onSuccessRequest: () => {},
    fileName: "resume.pdf"
  });

  // Get skill list
  const { data: skills } = useGetSkills({ keys: [newSkills] });

  const previewResume = (resume) => {
    let url;
    if (resume instanceof File) {
      url = URL.createObjectURL(resume);
    } else {
      const fileBlob = new Blob([resume], { type: "application/pdf" });
      const file = new File([fileBlob], "resume.pdf", { type: "application/pdf" });
      url = URL.createObjectURL(file);
    }
    window.open(url, "_blank");
    window.URL.revokeObjectURL(url);
  };

  // On photo upload
  const onPhotoUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 10 })) {
      alert("File size limit of 10 KB exceeded");
      setError(
        "jp_photo",
        { type: "custom", message: "File size limit of 10KB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors("jp_photo");
      setPhoto(file);
    }
  };

  // On resume upload
  const onFileUpload = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 100 })) {
      alert("File size limit of 100 KB exceeded");
      setError(
        "jp_resume",
        { type: "custom", message: "File size limit of 100 KB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors("jp_resume");
      setResume(file);
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (preloadedData && (preloadedData.jp_resume || preloadedData.jp_photo)) {
      clearErrors(["jp_resume", "jp_photo"]);
    }

    handleSubmit(onSubmitData)(event);
  };

  const onSubmitData = (data, event) => {
    const formData = new FormData(event.target);
    formData.set("jp_state", data.jp_state.label);
    formData.set("jp_qualification", data.jp_qualification.label);
    formData.set("jp_dob", data.jp_dob.toISOString());
    formData.set("jp_skills", convertListToString(data.jp_skills));
    formData.set("jp_language", convertListToString(data.jp_language));
    formData.set("jp_passingyear", data.jp_passingyear.toISOString());
    formData.set("jp_active", true);

    if (preloadedData && !preloadedData.jp_resume) {
      formData.append("jp_resume", resume);
    }

    if (preloadedData && !preloadedData.jp_photo) {
      formData.append("jp_photo", photo);
    }

    // For update operation jp_updated_at and jp_udpated_by fields are updated
    if (
      location.pathname.includes("/candidate/listings") ||
      location.pathname.includes("/profile")
    ) {
      // We do not want to update candidate's existing password when updating hence delete field
      formData.delete("jp_password");
      formData.set("jp_updated_at", getCurrentTimeStamp());
      formData.set("jp_updated_by", user.id);
    }

    // For new candidates added, jp_created_by and jp_created_at are updated
    if (location.pathname.includes("/candidate/addCandidate")) {
      // Setting a default password when HR adds a new candidate
      formData.set("jp_password", "password123$");
      formData.append("jp_created_by", user.id);
      formData.append("jp_yoe", data.jp_yoe);
      formData.append("jp_created_at", getCurrentTimeStamp());
    }
    mutationFn({ data: formData });
  };

  const actionButtonText = location.pathname.includes("/candidate/listings") ? "Update" : "Submit";

  return (
    <ContentBox>
      <AddNewSkillDialog
        open={skill}
        onClose={() => setSkill(false)}
        addFn={addSkill}
        loading={isAdding}
      />
      {/** View Profile Picture Dialog */}
      <ProfilePreviewDialog
        onClose={() => setViewProfile(false)}
        open={viewProfile}
        profile={previewPhoto}
      />
      <h1>{title}</h1>
      <FormProvider setValue={setValue} getFieldState={getFieldState} getValues={getValues}>
        <form onSubmit={onSubmit} encType="multipart/form-data">
          <Grid container>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box display={"flex"} gap={4}>
                <Box width={"100%"}>
                  <UploadFileTitle
                    title={"Upload Resume"}
                    actionTitle={isEditAction ? "View" : resume ? "Preview" : ""}
                    shouldPreview={resume || dbResume}
                    previewClick={() => {
                      if (preloadedData || dbResume || resume) {
                        if (resume) {
                          previewResume(resume);
                        } else if (dbResume) {
                          previewResume(dbResume);
                        }
                      }
                    }}
                  />
                  <FormUploadFile
                    name={"jp_resume"}
                    fileType={"application/pdf"}
                    errors={errors.jp_resume}
                    file={resume}
                    register={register}
                    preloadedData={preloadedData ? preloadedData.jp_resume : undefined}
                    helperText={"Please upload your resume"}
                    onUploadFile={onFileUpload}
                  />
                </Box>
                <Box width={"100%"}>
                  <UploadFileTitle
                    title={"Upload Photo"}
                    actionTitle={isEditAction ? "View" : photo ? "Preview" : ""}
                    shouldPreview={photo}
                    previewClick={() => setViewProfile(true)}
                  />
                  <FormUploadFile
                    name={"jp_photo"}
                    fileType={"image/*"}
                    errors={errors.jp_photo}
                    file={photo}
                    register={register}
                    helperText={"Please upload your profile picture"}
                    onUploadFile={onPhotoUpload}
                    preloadedData={preloadedData ? preloadedData.jp_photo : undefined}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <h2>Basic Details</h2>
              <Box display="flex" flexDirection="column" gap={4}>
                <Box display={"flex"} gap={4}>
                  <FormTextField
                    name="jp_candidate_name"
                    control={control}
                    label={"Full Name *"}
                    defaultValue={preloadedData ? preloadedData.jp_candidate_name : ""}
                    fullWidth={true}
                    rules={{ pattern: { value: NameRegex, message: "Invalid name format" } }}
                    helperText={"First name is required"}
                  />
                  <FormTextField
                    name="jp_email"
                    control={control}
                    label={"Email * "}
                    defaultValue={preloadedData ? preloadedData.jp_email : ""}
                    fullWidth={true}
                    type={"email"}
                    rules={{ pattern: { value: EmailRegex, message: "Invalid email format" } }}
                    helperText={"Email is required"}
                  />
                </Box>

                <Box display="flex" gap={4}>
                  <FormDatePicker
                    name="jp_dob"
                    label={"D.O.B * "}
                    defaultValue={preloadedData ? preloadedData.jp_dob : ""}
                    fullWidth={true}
                    rules={{ validate: { validateDate } }}
                    helperText={"Date of Birth is required"}
                    control={control}
                    disableFuture
                    handleOnChange={(value) => {
                      setValue("jp_age", calculateAge(value.$d));
                    }}
                  />
                  <FormTextField
                    name="jp_age"
                    control={control}
                    label={"Age *"}
                    fullWidth={true}
                    defaultValue={preloadedData ? preloadedData.jp_age : ""}
                    type={"number"}
                    rules={{ min: { value: 1, message: "Please enter valid age" } }}
                    InputLabelProps={{ shrink: true }}
                    onInput={filterAlphabets}
                    helperText={"Age is required"}
                  />
                </Box>
                <Box display={"flex"} gap={4}>
                  <FormTextField
                    name="jp_designation"
                    control={control}
                    label={"Designation"}
                    defaultValue={preloadedData ? preloadedData.jp_designation : ""}
                    fullWidth={true}
                  />
                  <FormTextField
                    name="jp_contactno"
                    control={control}
                    label={"Phone Number *"}
                    defaultValue={preloadedData ? preloadedData.jp_contactno : ""}
                    fullWidth={true}
                    type={"text"}
                    rules={{
                      pattern: { value: PhoneRegex, message: "Please enter valid phone number" }
                    }}
                    onInput={filterAlphabets}
                    inputProps={{ maxLength: 10 }}
                    helperText={"Phone Number is required"}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <h2>Address</h2>
              <Box display="flex" flexDirection="column" gap={3}>
                <FormTextField
                  name="jp_address"
                  control={control}
                  label={"Address street, house no., etc *"}
                  defaultValue={preloadedData ? preloadedData.jp_address : ""}
                  fullWidth={true}
                  helperText={"Address is required"}
                />
                <Box display="flex" gap={4}>
                  {/* City */}
                  <FormTextField
                    name="jp_city"
                    control={control}
                    label={"City *"}
                    defaultValue={preloadedData ? preloadedData.jp_city : ""}
                    fullWidth={true}
                    rules={{ pattern: { value: NameRegex, message: "Please enter valid city" } }}
                    helperText={"City name is required"}
                  />
                  {/* State */}
                  <FormAutocomplete
                    name="jp_state"
                    control={control}
                    label={"State *"}
                    values={States}
                    defaultValue={preloadedData ? preloadedData.jp_state : ""}
                    sxValues={{ width: "100%" }}
                    helperText={"Last name is required"}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <h2>Qualifications</h2>
              <Box display="flex" gap={4}>
                <FormAutocomplete
                  name="jp_qualification"
                  control={control}
                  label={"Highest Qualification Level *"}
                  values={QualificationOptions}
                  sxValues={{ flexGrow: 1 }}
                  defaultValue={preloadedData ? preloadedData.jp_qualification : ""}
                  helperText={"Qualification is required"}
                />
                <FormTextField
                  name="jp_degree_name"
                  control={control}
                  label={"Specialization"}
                  sx={{ flexGrow: 1 }}
                  defaultValue={preloadedData ? preloadedData.jp_degree_name : ""}
                />

                <FormDatePicker
                  name={"jp_passingyear"}
                  label={"Graduation Date *"}
                  control={control}
                  helperText={"Graduation date is required"}
                  rules={{ required: "Graduation date is required" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <h2>Current Employment Details</h2>
              <Box display={"flex"} gap={3}>
                {/* Current Company */}
                <FormTextField
                  name="jp_current_company"
                  control={control}
                  label={"Current Company"}
                  sx={{ flex: 1 }}
                  rules={{
                    pattern: { value: NameRegex, message: "Please enter valid company name" }
                  }}
                  defaultValue={preloadedData ? preloadedData.jp_current_company : ""}
                />
                {/* CTC */}
                <FormTextField
                  name={"jp_current_ctc"}
                  label={"Current CTC"}
                  defaultValue={preloadedData ? preloadedData.jp_current_ctc : ""}
                  sx={{ flex: 1 }}
                  rules={{
                    pattern: {
                      value: /^\d+$/,
                      message: "Please enter valid CTC"
                    }
                  }}
                  control={control}
                />
                {/* Notice Period */}
                <FormTextField
                  name={"jp_notice_period"}
                  label={"Notice Period (Mention in days)"}
                  defaultValue={preloadedData ? preloadedData.jp_notice_period : ""}
                  control={control}
                  type={"number"}
                  sx={{ flex: 1 }}
                  onInput={filterAlphabets}
                  rules={{
                    min: {
                      value: 0,
                      message: "Please enter a valid value"
                    }
                  }}
                />
                {/* Years of Experience */}
                <FormTextField
                  name={"jp_yoe"}
                  label={"Years of Experience"}
                  defaultValue={preloadedData ? preloadedData.jp_yoe : "0"}
                  control={control}
                  rules={{
                    min: { value: 0, message: "Please enter YOE greater than or equal to 0" }
                  }}
                  sx={{ flex: 1 }}
                  onInput={filterAlphabets}
                  type="number"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <h2>Skills & Language</h2>
                <Button
                  variant={"outlined"}
                  endIcon={<Add />}
                  size="small"
                  onClick={() => setSkill(true)}
                  style={{ paddingY: 0, maxHeight: "30px" }}
                >
                  <Typography variant="body2">Add Skill</Typography>
                </Button>
              </Box>
              <Box display={"flex"} flexDirection={"column"} gap={3}>
                <Stack direction={"row"} gap={4} flexGrow={1}>
                  {skills && (
                    <FormAutocompleteMulti
                      name="jp_skills"
                      control={control}
                      label={"Skills *"}
                      defaultValue={preSkills}
                      isOptionEqualToValue={(option, value) => {
                        return option.jp_id === value.jp_id;
                      }}
                      values={skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill }))}
                      sx={{ width: "100%" }}
                      helperText={"Skills is required"}
                    />
                  )}
                  <FormAutocompleteMulti
                    name="jp_language"
                    control={control}
                    label={"Languages Spoken *"}
                    defaultValue={preloadedData ? preloadedData.jp_language : ""}
                    values={Languages}
                    isOptionEqualToValue={(option, value) => option.label === value.label}
                    sx={{ flexGrow: 1 }}
                    helperText={"Language is required"}
                  />
                </Stack>
                <FormTextField
                  name={"jp_about_me"}
                  label={
                    user.role === "SA"
                      ? "Add description of candidate *"
                      : "Add a description about yourself *"
                  }
                  defaultValue={preloadedData ? preloadedData.jp_about_me : ""}
                  control={control}
                  helperText={"Candidate description is required"}
                  multiline
                  fullWidth
                  rows={10}
                  sx={{ mb: 4 }}
                />
              </Box>
            </Grid>
          </Grid>
          <LoadingButton
            loadingPosition="start"
            loading={mutationState}
            color="primary"
            variant="contained"
            type="submit"
            startIcon={<Send />}
          >
            {actionButtonText}
          </LoadingButton>
        </form>
      </FormProvider>
    </ContentBox>
  );
}

export default AddCandidateForm;
