import { useMemo } from "react";

const useCheckJobsData = (isAllJobs, isCompanyJobs, allJobs, companyJobs) => {
  return useMemo(() => {
    let tableData = [];
    let count = 0;

    if (isAllJobs) {
      count = allJobs.count;
      tableData = allJobs.data;
    } else if (isCompanyJobs) {
      count = companyJobs.count;
      tableData = companyJobs.data;
    } else {
      count = 0;
      tableData = [];
    }

    return { tableData, count };
  }, [allJobs, companyJobs]);
};

export default useCheckJobsData;
