import { getAllSkills } from "app/api/skill_api";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo, createContext } from "react";

const Constants = createContext();

const ConstantsProvider = ({ children }) => {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    (async () => {
      const skills = await getAllSkills();
      if (skills) {
        setSkills(skills.map(({ jp_id, jp_skill }) => ({ jp_id, label: jp_skill })));
      }
    })();
  }, []);

  return <Constants.Provider value={skills}>{children}</Constants.Provider>;
};

export { Constants, ConstantsProvider };
