import { getCandidateId } from "app/api/candidate_api";
import { useMutation } from "@tanstack/react-query";

const useCheckCandidateExists = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) => getCandidateId(params.email),
    onSuccess: onSuccess,
    onError: onError
  });
};

export default useCheckCandidateExists;
