import React from "react";
import { Backdrop } from "@mui/material";
function PreviewLogo({ open, onClick, children }) {
  return (
    <Backdrop
      open={open}
      onClick={onClick}
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      {children}
    </Backdrop>
  );
}

export default PreviewLogo;
