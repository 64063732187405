export const Countries = [
  { label: "Afghanistan" },
  { label: "Albania" },
  { label: "Algeria" },
  { label: "Andorra" },
  { label: "Angola" },
  { label: "Antigua & Deps" },
  { label: "Argentina" },
  { label: "Armenia" },
  { label: "Australia" },
  { label: "Austria" },
  { label: "Azerbaijan" },
  { label: "Bahamas" },
  { label: "Bahrain" },
  { label: "Bangladesh" },
  { label: "Barbados" },
  { label: "Belarus" },
  { label: "Belgium" },
  { label: "Belize" },
  { label: "Benin" },
  { label: "Bhutan" },
  { label: "Bolivia" },
  { label: "Bosnia Herzegovina" },
  { label: "Botswana" },
  { label: "Brazil" },
  { label: "Brunei" },
  { label: "Bulgaria" },
  { label: "Burkina" },
  { label: "Burundi" },
  { label: "Cambodia" },
  { label: "Cameroon" },
  { label: "Canada" },
  { label: "Cape Verde" },
  { label: "Central African Rep" },
  { label: "Chad" },
  { label: "Chile" },
  { label: "China" },
  { label: "Colombia" },
  { label: "Comoros" },
  { label: "Congo" },
  { label: "Congo {Democratic Rep}" },
  { label: "Costa Rica" },
  { label: "Croatia" },
  { label: "Cuba" },
  { label: "Cyprus" },
  { label: "Czech Republic" },
  { label: "Denmark" },
  { label: "Djibouti" },
  { label: "Dominica" },
  { label: "Dominican Republic" },
  { label: "East Timor" },
  { label: "Ecuador" },
  { label: "Egypt" },
  { label: "El Salvador" },
  { label: "Equatorial Guinea" },
  { label: "Eritrea" },
  { label: "Estonia" },
  { label: "Ethiopia" },
  { label: "Fiji" },
  { label: "Finland" },
  { label: "France" },
  { label: "Gabon" },
  { label: "Gambia" },
  { label: "Georgia" },
  { label: "Germany" },
  { label: "Ghana" },
  { label: "Greece" },
  { label: "Grenada" },
  { label: "Guatemala" },
  { label: "Guinea" },
  { label: "Guinea-Bissau" },
  { label: "Guyana" },
  { label: "Haiti" },
  { label: "Honduras" },
  { label: "Hungary" },
  { label: "Iceland" },
  { label: "India" },
  { label: "Indonesia" },
  { label: "Iran" },
  { label: "Iraq" },
  { label: "Ireland {Republic}" },
  { label: "Israel" },
  { label: "Italy" },
  { label: "Ivory Coast" },
  { label: "Jamaica" },
  { label: "Japan" },
  { label: "Jordan" },
  { label: "Kazakhstan" },
  { label: "Kenya" },
  { label: "Kiribati" },
  { label: "Korea North" },
  { label: "Korea South" },
  { label: "Kosovo" },
  { label: "Kuwait" },
  { label: "Kyrgyzstan" },
  { label: "Laos" },
  { label: "Latvia" },
  { label: "Lebanon" },
  { label: "Lesotho" },
  { label: "Liberia" },
  { label: "Libya" },
  { label: "Liechtenstein" },
  { label: "Lithuania" },
  { label: "Luxembourg" },
  { label: "Macedonia" },
  { label: "Madagascar" },
  { label: "Malawi" },
  { label: "Malaysia" },
  { label: "Maldives" },
  { label: "Mali" },
  { label: "Malta" },
  { label: "Marshall Islands" },
  { label: "Mauritania" },
  { label: "Mauritius" },
  { label: "Mexico" },
  { label: "Micronesia" },
  { label: "Moldova" },
  { label: "Monaco" },
  { label: "Mongolia" },
  { label: "Montenegro" },
  { label: "Morocco" },
  { label: "Mozambique" },
  { label: "Myanmar, {Burma}" },
  { label: "Namibia" },
  { label: "Nauru" },
  { label: "Nepal" },
  { label: "Netherlands" },
  { label: "New Zealand" },
  { label: "Nicaragua" },
  { label: "Niger" },
  { label: "Nigeria" },
  { label: "Norway" },
  { label: "Oman" },
  { label: "Pakistan" },
  { label: "Palau" },
  { label: "Panama" },
  { label: "Papua New Guinea" },
  { label: "Paraguay" },
  { label: "Peru" },
  { label: "Philippines" },
  { label: "Poland" },
  { label: "Portugal" },
  { label: "Qatar" },
  { label: "Romania" },
  { label: "Russian Federation" },
  { label: "Rwanda" },
  { label: "St Kitts & Nevis" },
  { label: "St Lucia" },
  { label: "Saint Vincent & the G}ernadines" },
  { label: "Samoa" },
  { label: "San Marino" },
  { label: "Sao Tome & Principe" },
  { label: "Saudi Arabia" },
  { label: "Senegal" },
  { label: "Serbia" },
  { label: "Seychelles" },
  { label: "Sierra Leone" },
  { label: "Singapore" },
  { label: "Slovakia" },
  { label: "Slovenia" },
  { label: "Solomon Islands" },
  { label: "Somalia" },
  { label: "South Africa" },
  { label: "South Sudan" },
  { label: "Spain" },
  { label: "Sri Lanka" },
  { label: "Sudan" },
  { label: "Suriname" },
  { label: "Swaziland" },
  { label: "Sweden" },
  { label: "Switzerland" },
  { label: "Syria" },
  { label: "Taiwan" },
  { label: "Tajikistan" },
  { label: "Tanzania" },
  { label: "Thailand" },
  { label: "Togo" },
  { label: "Tonga" },
  { label: "Trinidad & Tobago" },
  { label: "Tunisia" },
  { label: "Turkey" },
  { label: "Turkmenistan" },
  { label: "Tuvalu" },
  { label: "Uganda" },
  { label: "Ukraine" },
  { label: "United Arab Emirates" },
  { label: "United Kingdom" },
  { label: "United States" },
  { label: "Uruguay" },
  { label: "Uzbekistan" },
  { label: "Vanuatu" },
  { label: "Vatican City" },
  { label: "Venezuela" },
  { label: "Vietnam" },
  { label: "Yemen" },
  { label: "Zambia" },
  { label: "Zimbabwe" }
];

export const Industries = [
  { id: 1, label: "Entertainment" },
  { id: 2, label: "Finance" },
  { id: 3, label: "Medical" },
  { id: 4, label: "Software" },
  { id: 5, label: "Education" },
  { id: 6, label: "Textile" },
  { id: 7, label: "Media and News" },
  { id: 8, label: "Food Processing" },
  { id: 9, label: "Hospitality" },
  { id: 10, label: "Construction" },
  { id: 11, label: "Law" },
  { id: 12, label: "Advertising" }
];

export const Languages = [
  { id: 0, label: "Telugu" },
  { id: 1, label: "Nishi" },
  { id: 2, label: "Adi" },
  { id: 3, label: "Apatani" },
  { id: 4, label: "Galo" },
  { id: 5, label: "Monpa" },
  { id: 6, label: "Assamese" },
  { id: 7, label: "Bodo" },
  { id: 8, label: "Karbi" },
  { id: 9, label: "Mising" },
  { id: 10, label: "Rabha" },
  { id: 11, label: "Bhojpuri" },
  { id: 12, label: "Maithili" },
  { id: 13, label: "Magahi" },
  { id: 14, label: "Angika" },
  { id: 15, label: "Chhattisgarhi" },
  { id: 16, label: "Konkani" },
  { id: 17, label: "Gujarati" },
  { id: 18, label: "Haryanvi" },
  { id: 19, label: "Hindi" },
  { id: 20, label: "Kangri" },
  { id: 21, label: "Santali" },
  { id: 22, label: "Kannada" },
  { id: 23, label: "Malayalam" },
  { id: 24, label: "Marathi" },
  { id: 25, label: "Meitei" },
  { id: 26, label: "Khasi" },
  { id: 27, label: "Garo" },
  { id: 28, label: "Mizo" },
  { id: 29, label: "Ao" },
  { id: 30, label: "Konyak" },
  { id: 31, label: "Angami" },
  { id: 32, label: "Chakhesang" },
  { id: 33, label: "Odia" },
  { id: 34, label: "Punjabi" },
  { id: 35, label: "Rajasthani" },
  { id: 36, label: "Nepali" },
  { id: 37, label: "Bhutia" },
  { id: 38, label: "Lepcha" },
  { id: 39, label: "Limbu" },
  { id: 40, label: "Tamil" },
  { id: 41, label: "Kokborok" },
  { id: 42, label: "Awadhi" },
  { id: 43, label: "Braj" },
  { id: 44, label: "Bundeli" },
  { id: 45, label: "Kannauji" },
  { id: 46, label: "Garhwali" },
  { id: 47, label: "Kumaoni" },
  { id: 48, label: "Bengali" },
  { id: 49, label: "English" }
];
