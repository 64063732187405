import { convertISODateTimeToDate } from "app/utils/utils";
import { useMemo } from "react";

export const useCompanyApplicantExtract = (applicants, userId, dependency) => {
  return useMemo(() => {
    const result = applicants.data.map((applicant) => ({
      jp_id: applicant.jp_id,
      jp_created_at: convertISODateTimeToDate(applicant.jp_created_at),
      jp_company_id: userId,
      jp_candidate_id: applicant.Candidate.jp_id,
      jp_candidate_name: applicant.Candidate.jp_candidate_name,
      jp_title: applicant.jobposting.jp_title,
      jp_current_ctc: applicant.Candidate.jp_current_ctc,
      jp_notice_period: applicant.Candidate.jp_notice_period,
      jp_status: applicant.jp_status
    }));
    return { rows: result, count: applicants.count };
  }, [dependency]);
};
