import React from "react";
import { Box, TextField } from "@mui/material";
import AutoCompleteMulti from "app/views/material-kit/auto-complete/AutoCompleteMulti";
import { useContext } from "react";
import { Constants } from "app/contexts/ConstantContext";
function CandidateFilter({
  selectedLocations,
  selectedSkill,
  experience,
  locations,
  setSkill,
  setLocation,
  setExperience
}) {
  const skills = useContext(Constants);
  return (
    <Box display={"flex"} gap={3} my={2}>
      <AutoCompleteMulti // Filter by Skill
        onSelection={(value) => {
          setSkill(value);
        }}
        label={"Filter by Skills"}
        values={skills}
        selectedValue={selectedSkill}
      />
      <AutoCompleteMulti // Filter By Location
        onSelection={(value) => setLocation(value)}
        label={"Filter by Location"}
        values={locations}
        size={"small"}
        selectedValue={selectedLocations}
      />
      <TextField
        label="Years of Exp ( Result >= Y.O.E )"
        type="number"
        value={experience}
        size="small"
        defaultValue={0}
        sx={{ minWidth: "200px" }}
        onChange={(event) => setExperience(event.target.value)}
        InputProps={{
          inputProps: { min: 0, max: 100 }
        }}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  );
}

export default CandidateFilter;
