import React from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { Send } from "@mui/icons-material";
import { H3, H2, Paragraph } from "app/components/Typography";
import { usePostApplication } from "app/hooks/application/usePostApplication";
import { useQuery } from "@tanstack/react-query";
import { getCandidateApplications } from "app/api/application_api";
import { useMemo } from "react";
import DetailAccordian from "./DetailAccordian";
import { useGetCompany } from "app/hooks/company/useGetCompany";
import { convertToBase64 } from "app/utils/utils";
import ReadMoreText from "app/components/ReadMoreText";
import { LoadingButton } from "@mui/lab";
import useAuth from "app/hooks/useAuth";
import useNotification from "app/hooks/useNotification";
import { useState } from "react";
import { Link } from "react-router-dom";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";

function ApplyDialogContent({ job, onClose }) {
  const { user } = useAuth();
  const { createNotification } = useNotification();
  const [apply, setApply] = useState(false);
  const notification = {
    jp_title: `New Application`,
    jp_message: `${user.name} has applied for the role of ${job.jp_title}`,
    jp_status: "pending"
  };

  const companyNotification = {
    jp_title: `New Application`,
    jp_message: `${user.name} has applied for the role of ${job.jp_title}`,
    jp_company_id: job.jp_company_id
  };

  const { data: candidate, isFetched: isCandidateFetched } = useGetCandidate(user.id, "", Infinity);

  // Create a new job application for the candidate
  const { mutate: postApplication, isSuccess } = usePostApplication({
    onApply: () => setApply(true),
    onSuccess: async (data) => {
      createNotification(notification, "admin");
      createNotification(companyNotification, "company");
      setApply(false);
      onClose();
    }
  });

  const { data: applications, isFetched: isApplyFetched } = useQuery({
    queryKey: ["applicationByCandidate", isSuccess],
    queryFn: () => getCandidateApplications(user.id)
  });
  const { data: company } = useGetCompany({ id: job.jp_company_id });

  const isAppliedJob = useMemo(() => {
    if (applications) {
      const currentJob = applications.find((item) => {
        return item.jp_jobpost_id === job.jp_id;
      });
      if (currentJob !== undefined) {
        return true;
      }
    }

    return false;
  }, [applications, job.jp_id]);

  const application = {
    jp_company_id: job.jp_company_id,
    jp_jobpost_id: job.jp_id,
    jp_candidate_id: user.id,
    jp_status: "pending",
    jp_is_share_to_company: 0,
    mail: {
      jp_candidate_name: user.name,
      jp_email: user.email,
      jp_job_title: job.jp_title,
      jp_phone: candidate ? candidate.jp_contactno : "Not specified",
      jp_company_name: company ? company.jp_company_name : ""
    }
  };

  const requiredSkills = job.jp_required_skills
    ? job.jp_required_skills
        .split("| ")
        .map((skill) => (
          <Chip
            key={skill}
            label={skill}
            sx={{ marginRight: "5px", bgcolor: "green", color: "white" }}
          />
        ))
    : ["No skill specified"];

  return (
    <Box display={"flex"} flexDirection={"column"} gap={2} sx={{ paddingX: 2, paddingY: 1 }}>
      <Box display={"flex"} gap={2}>
        <Box>
          <Avatar
            sx={{ width: 56, height: 56 }}
            src={`data:image/jpeg;base64,${convertToBase64(
              company ? (company.jp_logo ? company.jp_logo : "") : ""
            )}`}
          />
        </Box>
        <Box>
          <H2>{job.jp_company_name}</H2>
          <ReadMoreText text={company ? company.jp_about_company : ""} maxChars={200} />
        </Box>
      </Box>
      <H3>Job Description</H3>
      <Paragraph>{job.jp_description}</Paragraph>
      <Box display="flex" flexDirection="column" gap={1}>
        <DetailAccordian
          title={"Requirements"}
          details={[
            <Typography key={"qualification"}>Qualifications: {job.jp_qualification}</Typography>,
            <Typography key={"experience"}>Experience: {job.jp_experience}</Typography>,
            <Typography key={"location"}>Location: {job.jp_location}</Typography>
          ]}
        />
        <DetailAccordian
          title={"Salary Information"}
          details={[
            <Typography key={"min_salary"}>Starting CTC: Rs. {job.jp_minimum_salary}</Typography>,
            <Typography key={"max_salary"}>Maximum CTC: Rs. {job.jp_maximum_salary}</Typography>
          ]}
        />
        <DetailAccordian
          title={"Benefits"}
          details={[
            <Box display={"flex"} flexWrap={"wrap"} key={"benefits"} gap={1}>
              {job.Benefits.length > 0 &&
                job.Benefits.map((benefit) => (
                  <Chip
                    key={benefit.jp_id}
                    label={benefit.jp_benefit}
                    sx={{ marginRight: "5px", bgcolor: "green", color: "white" }}
                  />
                ))}
              {job.Benefits.length === 0 && <Typography>Not specified</Typography>}
            </Box>
          ]}
        />
        <DetailAccordian
          title={"Skills Required"}
          details={[
            <Box display={"flex"} flexWrap={"wrap"} key={"benefits"} gap={1}>
              {requiredSkills}
            </Box>
          ]}
        />
      </Box>
      <Box display={"flex"} alignSelf={"flex-end"} gap={2}>
        {isApplyFetched && isAppliedJob && (
          <H2
            sx={{
              color: "green",
              backgroundColor: "lightgreen",
              padding: "4px 6px 4px 6px",
              borderRadius: "5px",
              fontWeight: 100,
              width: "120px",
              textAlign: "center"
            }}
          >
            Applied
          </H2>
        )}
        {isCandidateFetched && !candidate.jp_resume && !isAppliedJob && (
          <Link to={"/profile"} state={{ tab: "2" }}>
            <H3
              sx={{
                color: "red",
                backgroundColor: "#FFCCCC",
                padding: "4px 6px 4px 6px",
                borderRadius: "5px",
                fontWeight: 100,
                textAlign: "center",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#FF9999"
                }
              }}
            >
              No Resume Added. Click to add.
            </H3>
          </Link>
        )}
        {isApplyFetched && !isAppliedJob && isCandidateFetched && candidate.jp_resume && (
          <LoadingButton
            loadingPosition={"start"}
            loading={apply}
            variant="contained"
            onClick={() => postApplication({ data: application })}
            startIcon={<Send />}
          >
            <span>Apply</span>
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}

export default ApplyDialogContent;
