import { useMutation } from "@tanstack/react-query";
import { updateCompanyPassword } from "app/api/company_api";

export const useUpdateCompanyPass = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) =>
      updateCompanyPassword(params.id, params.newPass, params.oldPass, params.reset),
    onSuccess: onSuccess,
    onError: onError
  });
};
