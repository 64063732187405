export const filterState = {
  type: [],
  exp: [],
  skill: 0,
  location: []
};

export const filterAction = {
  SET_TYPE: "SET_TYPE",
  SET_EXP: "SET_EXP",
  SET_SKILL: "SET_SKILL",
  SET_LOCATION: "SET_LOCATION"
};

export function filterReducer(state, action) {
  switch (action.type) {
    case filterAction.SET_TYPE:
      const { newType, checked } = action.payload;
      let selectedType = state.type;
      if (checked) {
        selectedType = [...selectedType, newType];
      } else {
        selectedType = selectedType.filter((selected) => selected !== newType);
      }
      return { ...state, type: selectedType };
    case filterAction.SET_EXP:
      let selectedExp = state.exp;
      if (action.payload.checked) {
        selectedExp = [...selectedExp, action.payload.exp];
      } else {
        selectedExp = selectedExp.filter((selected) => selected !== action.payload.exp);
      }
      return { ...state, exp: selectedExp };
    case filterAction.SET_SKILL:
      return { ...state, skill: action.payload };
    case filterAction.SET_LOCATION:
      let selectedLocation = state.location;
      if (action.payload.checked) {
        selectedLocation = [...selectedLocation, action.payload.location];
      } else {
        selectedLocation = selectedLocation.filter(
          (selected) => selected !== action.payload.location
        );
      }
      return { ...state, location: selectedLocation };
  }
}
