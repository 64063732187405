import { useQuery } from "@tanstack/react-query";
import { getCandidateResume } from "app/api/candidate_api";

export const useGetCandidateResume = ({ id, fileName }) => {
  return useQuery({
    queryKey: ["GetCandidateResume", id],
    queryFn: () => {
      return getCandidateResume(id, fileName);
    },
    enabled: !!id
  });
};
