import React from "react";
import { Box, FormGroup, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
function PopoverContent({ value, options, onChange, title }) {
  return (
    <Box display={"flex"} flexDirection={"column"} py={1} px={2}>
      <h4>{title}</h4>
      <FormGroup>
        {options.map((item) => {
          return (
            <FormControlLabel
              key={item.value}
              label={item.label}
              control={
                <Checkbox
                  checked={value.includes(item.value)}
                  value={item.value}
                  onChange={(event) => {
                    onChange(event);
                  }}
                />
              }
            />
          );
        })}
      </FormGroup>
    </Box>
  );
}

export default PopoverContent;
