import React from "react";
import { MenuItem, Select, Tooltip } from "@mui/material";
import { useState } from "react";
import useNotification from "app/hooks/useNotification";
import useAuth from "app/hooks/useAuth";
import { useUpdateStatus } from "app/hooks/application/useUpdateStatus";
function StatusDropdown({
  status,
  shareStatus,
  applicationId,
  candidateId,
  candidateName,
  companyName,
  companyId,
  companyMail,
  jobTitle
}) {
  const [selectedValue, setSelectedValue] = useState(status);
  const { createNotification } = useNotification();
  const { user } = useAuth();
  let bgColor = "transparent";
  let textColor = "black";

  if (selectedValue === "rejected") {
    textColor = "red";
    bgColor = "#ef9a9a";
  } else if (selectedValue === "pending") {
    textColor = "gray";
    bgColor = "lightgray";
  } else if (selectedValue === "accepted") {
    textColor = "green";
    bgColor = "lightGreen";
  } else if (selectedValue === "shortlisted") {
    textColor = "#2196f3";
    bgColor = "lightblue";
  }

  const createNotificationMessage = (candidate, jobTitle, status) => {
    return `Updated application status of candidate ${candidate} for ${jobTitle} to ${status.toUpperCase()}`;
  };

  const sendNotification = () => {
    console.log(user);
    if (
      selectedValue === "shortlisted" ||
      selectedValue === "accepted" ||
      selectedValue === "rejected"
    ) {
      createNotification(
        {
          jp_candidate_id: candidateId,
          jp_title: `Message from ${user.name}`,
          jp_message: `Your application for ${jobTitle} has been ${selectedValue}`,
          jp_status: selectedValue,
          jp_company_name: companyName,
          jp_job_title: jobTitle,
          jp_candidate_name: candidateName
        },
        "candidate"
      );

      // Company notifies HR when changing the application status of a candidate
      if (user.role === "company") {
        createNotification(
          {
            jp_title: `Message from Company ${companyName}`,
            jp_message: createNotificationMessage(candidateName, jobTitle, selectedValue),
            jp_status: selectedValue,
            jp_company_name: companyName,
            jp_candidate_name: candidateName
          },
          "admin"
        );
      }

      // HR notifies company when changing the application status of a candidate
      if (user.role === "SA") {
        createNotification(
          {
            jp_company_id: companyId,
            jp_title: `Application Update from HR Reyan`,
            jp_message: createNotificationMessage(candidateName, jobTitle, selectedValue),
            jp_status: selectedValue,
            mail: {
              jp_email: companyMail,
              jp_candidate_name: candidateName,
              jp_company_name: companyName,
              jp_status: selectedValue
            }
          },
          "company"
        );
      }
    }
  };
  const { mutate: updateStatus } = useUpdateStatus(applicationId, sendNotification, (error) =>
    console.log(error)
  );

  return (
    <Tooltip
      title={"Share candidate first to change status"}
      disableHoverListener={shareStatus === 0 ? false : true}
    >
      <Select
        value={selectedValue}
        disabled={shareStatus === 0 ? true : false}
        sx={{ minWidth: 150, "& fieldset": { border: "none" }, textAlign: "center" }}
        style={{ border: "none", backgroundColor: bgColor, color: textColor }}
        onChange={(event) => {
          setSelectedValue(event.target.value);
          updateStatus({ status: { jp_status: event.target.value } });
        }}
      >
        <MenuItem value={"pending"}>Pending</MenuItem>
        <MenuItem value={"shortlisted"}>Short-Listed</MenuItem>
        <MenuItem value={"accepted"}>Accepted</MenuItem>
        <MenuItem value={"rejected"}>Rejected</MenuItem>
      </Select>
    </Tooltip>
  );
}

export default StatusDropdown;
