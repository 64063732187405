import CustomDialog from "app/components/CustomDialog";
import { DialogContentText, TextField, DialogActions, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { capitalizeFirstLetter, capitalizeWords } from "app/utils/utils";

function AddNewBenefitsDialog({ open, onClose, addFn, loading }) {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      dialogTitle={"Add New Benefits"}
      PaperProps={{
        component: "form",
        onSubmit: (event) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries(formData.entries());
          const benefits = capitalizeWords(formJson.jp_benefits.split(","));
          addFn({ jp_benefits: benefits });
          onClose();
        }
      }}
    >
      <DialogContentText>
        Add new benefits separated by a comma e.g Benefit1, Benefit2, Benefit3 etc
      </DialogContentText>
      <TextField
        autoFocus
        required
        margin="dense"
        id="jp_benefits"
        name="jp_benefits"
        label="Benefits"
        sx={{ mt: 2 }}
        fullWidth
      />
      <DialogActions>
        <Button style={{ color: "gray" }} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" type="submit" loading={loading}>
          Add New Benefits
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  );
}

export default AddNewBenefitsDialog;
