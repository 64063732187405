import React from "react";
import { Autocomplete, TextField } from "@mui/material";
function AutoCompleteMulti({ label, values, selectedValue, onSelection, ...rest }) {
  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      options={values}
      size="small"
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      value={selectedValue || null}
      style={{ minWidth: "250px" }}
      onChange={(event, value) => {
        onSelection(value);
      }}
      renderInput={(params) => <TextField {...params} placeholder={label} />}
    ></Autocomplete>
  );
}

export default AutoCompleteMulti;
