import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import React from "react";
import { useForm } from "react-hook-form";
function UploadPhoto({ open, handleClose, mutateFn }) {
  const { handleSubmit, register } = useForm();
  const onSubmit = (data, e) => {
    const fd = new FormData(e.target);
    mutateFn({ admin: fd });
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: 600, // Width of the dialog
          height: 300 // Height of the dialog
        }
      }}
    >
      <DialogTitle>Upload Photo</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <FormUploadFile
            name={"jp_photo"}
            fileType={"image/*"}
            register={register}
            helperText={"Please upload your resume"}
            onUploadFile={() => {}}
          />
          <Button type="submit" variant="contained" sx={{ marginTop: "5px" }}>
            Upload
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default UploadPhoto;
