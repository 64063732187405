import React from "react";
import { Avatar, Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { H1, Paragraph } from "app/components/Typography";
import ChangePassword from "./ChangePassword";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAdmin } from "app/api/admin_api";
import useAuth from "app/hooks/useAuth";
import { useState } from "react";
import AddAdminForm from "app/views/admin/AddAdminForm";
import { updateAdmin } from "app/api/admin_api";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { Edit } from "@mui/icons-material";
import { convertToBase64 } from "app/utils/utils";
import UploadPhoto from "./UploadPhoto";

function AdminProfile(props) {
  const { user, updateUser } = useAuth();
  const [upload, setUpload] = useState(false); // Controls upload dialog visibility
  const [tab, setTab] = useState("1");
  const [updateStatus, setUpdateStatus] = useState({
    success: false,
    error: false,
    message: ""
  });

  const { mutate: update, isPending: isUpdating } = useMutation({
    mutationFn: (params) => updateAdmin(params.admin, user.id),
    onSuccess: (res) => {
      setUpdateStatus((prev) => ({ ...prev, success: true }));
      const { data } = res;
      updateUser(data.data);
      setUpload(false);
    },
    onError: (error) => setUpdateStatus((prev) => ({ ...prev, error: true }))
  });

  const { data: admin } = useQuery({
    queryKey: ["admin", isUpdating, tab],
    queryFn: () => getAdmin(user.id)
  });

  const handleUploadPhoto = () => {
    setUpload(true);
  };

  const handleTabChange = (event, value) => setTab(value);

  const successMessage =
    tab === "1" ? "Profile picture updated successfully" : "Profile updated successfully";

  return (
    <Box>
      {updateStatus.success && (
        <ResponseSnackbar
          message={successMessage}
          open={updateStatus.success}
          severity={"success"}
          onClose={() => setUpdateStatus((prev) => ({ ...prev, success: false }))}
        />
      )}
      {updateStatus.error && (
        <ResponseSnackbar
          message={"Error updating profile details"}
          open={updateStatus.error}
          severity={"error"}
          onClose={() => setUpdateStatus((prev) => ({ ...prev, error: false }))}
        />
      )}
      <h1>Profile</h1>
      <UploadPhoto open={upload} handleClose={() => setUpload(false)} mutateFn={update} />
      {admin && (
        <Grid container gap={4}>
          <Grid item justifyContent={"center"} xs={12}>
            <Box>
              <TabContext value={tab}>
                <TabList onChange={handleTabChange}>
                  <Tab label="About" value={"1"} />
                  <Tab label="Edit" value={"2"} />
                  <Tab label="Security" value={"3"} />
                </TabList>
                <TabPanel value={"1"}>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Box
                      position={"relative"}
                      bgcolor={"lightgray"}
                      sx={{ padding: 1, borderRadius: 5 }}
                      onClick={handleUploadPhoto}
                    >
                      <Avatar
                        style={{ width: 200, height: 200 }}
                        src={`data:image/jpeg;base64,${convertToBase64(
                          admin ? admin.jp_photo.data : ""
                        )}`}
                      />
                      <Edit sx={{ position: "absolute", bottom: 10, right: 10 }} />
                    </Box>
                    <H1>{admin.jp_name}</H1>
                    <Paragraph sx={{ fontSize: 18 }}>@{admin.jp_username}</Paragraph>
                    <Paragraph sx={{ fontSize: 18 }}>{admin.jp_email}</Paragraph>
                  </Box>
                </TabPanel>
                <TabPanel value={"2"}>
                  {admin && (
                    <AddAdminForm
                      mutationFn={update}
                      preloadedData={admin}
                      title={"Edit Profile"}
                    />
                  )}
                </TabPanel>
                <TabPanel value={"3"}>
                  <ChangePassword />
                </TabPanel>
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default AdminProfile;
