import { useMutation } from "@tanstack/react-query";
import { postJob } from "app/api/job_api";

export const usePostJob = (onSuccess, onError) => {
  return useMutation({
    mutationFn: (params) => postJob(params.data),
    onError: (error) => onError(),
    onSuccess: (data, variables, context) => onSuccess()
  });
};
