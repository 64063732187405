import { useQuery } from "@tanstack/react-query";
import { getCandidates } from "app/api/candidate_api";
const useGetCandidates = ({
  sortOrder,
  column,
  limit,
  offset,
  searchTerm,
  exp,
  location,
  skills,
  update,
  staleTime = 5000,
  ...rest
}) => {
  return useQuery({
    queryKey: [
      "candidates",
      limit,
      sortOrder,
      offset,
      searchTerm,
      exp,
      location,
      skills,
      update,
      rest
    ],
    queryFn: () =>
      getCandidates(sortOrder, column, limit, offset, searchTerm, exp, location, skills),
    staleTime: staleTime
  });
};

export default useGetCandidates;
