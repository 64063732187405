import { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Box, Button } from "@mui/material";
import { H2, H4, Paragraph } from "app/components/Typography";
import StatusText from "app/components/StatusText";
import JobDetailDialog from "app/views/jobs/shared/JobDetailDialog";
import useGetJob from "app/hooks/job/useGetJob";
function AppliedJobCard({ jobId, jobTitle, companyName, description, status }) {
  const [detailOpen, setDetailOpen] = useState(false);
  const { data: job } = useGetJob(jobId);
  return (
    <Card sx={{ width: "100%", color: "black" }}>
      <CardContent>
        {job && (
          <JobDetailDialog
            open={detailOpen}
            jobPosting={job}
            handleClose={() => setDetailOpen(false)}
          />
        )}
        <Box display="flex" flexDirection={"column"} justifyContent={"flex-start"} gap={2}>
          <Box>
            <Box display="flex" gap={1} justifyContent={"space-between"}>
              <H2>{jobTitle.trim()}</H2>
              <StatusText status={status} />
            </Box>
            <H4 marginBottom={2}>{companyName}</H4>
            <Paragraph>{description}</Paragraph>
          </Box>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          onClick={() => setDetailOpen(true)}
          variant="outlined"
          // sx={{
          //   color: "white",
          //   border: "1px solid white",
          //   "&:hover": { border: "2px solid white" }
          // }}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
}

export default AppliedJobCard;
