import { useMutation } from "@tanstack/react-query";
import { updateJob } from "app/api/job_api";
export const useUpdateJob = ({ id, onSuccess, onError, onSettled }) => {
  return useMutation({
    mutationFn: (params) => updateJob(params.data, id),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: () => onError(),
    onSettled: () => onSettled()
  });
};
