import ContentBox from "app/views/jobs/shared/ContentBox";
import FullScreenDialog from "app/views/material-kit/dialog/FullScreenDialog";
import AlertDialog from "app/views/material-kit/dialog/SimpleAlerts";
import React from "react";
import { Fragment } from "react";
import { Box } from "@mui/material";
import SearchBox from "./SearchBox";
function ListWrapper({
  editForm,
  editTitle,
  editOpen,
  handleEditClose,
  handleEditAction,
  title,
  onChangeSearch,
  alertTitle,
  alertMessage,
  alertOpen,
  alertCloseAction,
  alertAcceptAction,
  searchlabel,
  deleteLoadingState,
  children
}) {
  return (
    <Fragment>
      <FullScreenDialog
        title={editTitle}
        open={editOpen}
        handleClose={handleEditClose}
        handleEditAction={handleEditAction}
      >
        {editForm}
      </FullScreenDialog>
      <AlertDialog
        open={alertOpen}
        title={alertTitle}
        content={alertMessage}
        negAction={alertCloseAction}
        posAction={alertAcceptAction}
        loadingState={deleteLoadingState}
      />
      <ContentBox>
        <Box display={"flex"} justifyContent={"space-between"}>
          <h1>{title}</h1>
          <SearchBox label={searchlabel ? searchlabel : "Search"} onChange={onChangeSearch} />
        </Box>
        {children}
      </ContentBox>
    </Fragment>
  );
}

export default ListWrapper;
