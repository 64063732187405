import { useMutation } from "@tanstack/react-query";
import { updateStatus } from "app/api/application_api";

export const useUpdateStatus = (id, onSuccess, onError) => {
  return useMutation({
    mutationFn: (params) => updateStatus(id, params.status),
    onSuccess: () => onSuccess(),
    onError: (error) => onError(error)
  });
};
