import { useRoutes } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";

import { MatxTheme } from "./components";
// ALL CONTEXTS
import { AuthProvider } from "./contexts/JWTAuthContext";
import SettingsProvider from "./contexts/SettingsContext";
// ROUTES
import routes from "./routes";
// FAKE SERVER
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConstantsProvider } from "./contexts/ConstantContext";
import { NotificationProvider } from "./contexts/NotificationContext";

const queryClient = new QueryClient();
export default function App() {
  const content = useRoutes(routes);
  return (
    <QueryClientProvider client={queryClient}>
      <SettingsProvider>
        <AuthProvider>
          <NotificationProvider>
            <ConstantsProvider>
              <MatxTheme>
                <CssBaseline />
                {content}
              </MatxTheme>
            </ConstantsProvider>
          </NotificationProvider>
        </AuthProvider>
      </SettingsProvider>
    </QueryClientProvider>
  );
}
