import React from "react";
import { Box, Avatar } from "@mui/material";
import { H2 } from "app/components/Typography";
import EmptyBox from "../../../../assets/illustrations/empty_box.png";
function NoJobs(props) {
  return (
    <Box padding={4} sx={{ textAlign: "center" }}>
      <Avatar
        src={EmptyBox}
        sx={{ width: "64px", height: "64px", marginX: "auto", marginBottom: "30px" }}
      />
      <H2>No Jobs</H2>
    </Box>
  );
}

export default NoJobs;
