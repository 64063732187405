import React from "react";
import { styled } from "@mui/material";
const ContentBoxWrapper = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" }
}));
function ContentBox({ children }) {
  return <ContentBoxWrapper>{children}</ContentBoxWrapper>;
}

export default ContentBox;
