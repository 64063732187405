import PaginationTable from "../material-kit/tables/PaginationTable";
import { useState } from "react";
import AddCandidateForm from "./shared/AddCandidateForm";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import useGetCandidates from "app/hooks/candidate/useGetCandidates";
import useGetCandidate from "app/hooks/candidate/useGetCandidate";
import useUpdateCandidate from "app/hooks/candidate/useUpdateCandidate";
import useRemoveCandidate from "app/hooks/candidate/useRemoveCandidate";
import ListWrapper from "app/components/ListWrapper";
import { CandidateTableHeaders } from "app/utils/constant";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import useAuth from "app/hooks/useAuth";
import CandidateFilter from "./shared/CandidateFilter";
import { States } from "./shared/Constants";
import CustomDialog from "app/components/CustomDialog";
import CandidateDetail from "../applied_candidate/shared/CandidateDetail";
import { MatxLoading } from "app/components";

function CandidateList(props) {
  const { user } = useAuth();

  const [editOpen, setEditOpen] = useState(false); // Controls opening and closing of edit dialog
  const [deleteOpen, setDeleteOpen] = useState(false); // Controls opening and closing of delete dialog
  const [detailOpen, setDetailOpen] = useState(false); // Controls opening and closing of detail dialog
  const [sortOrder, setSortOrder] = useState("DESC");
  const [column, setColumn] = useState();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [rowId, setRowId] = useState();

  const [skill, setSkill] = useState([]);
  const [location, setLocation] = useState([]);
  const [experience, setExp] = useState(); // Years of Experience

  const [searchTerm, setSearchTerm] = useState("");

  const [snackBar, setSnackBar] = useState({
    updateSuccess: false,
    updateFail: false,
    deleteSuccess: false,
    deleteFail: false
  });

  const handleSnackbarClose = () => {
    if (snackBar.updateSuccess) {
      setSnackBar((prevState) => ({ ...prevState, updateSuccess: false }));
    } else if (snackBar.updateFail) {
      setSnackBar((prevState) => ({ ...prevState, updateFail: false }));
    } else if (snackBar.deleteSuccess) {
      setSnackBar((prevState) => ({ ...prevState, deleteSuccess: false }));
    } else if (snackBar.deleteFail) {
      setSnackBar((prevState) => ({ ...prevState, deleteFail: false }));
    }
  };

  const handleEditAction = (candidate) => {
    setEditOpen(true);
    setRowId(candidate.jp_id);
  };

  const handleDeleteClose = () => setDeleteOpen(false);
  const handleEditClose = () => setEditOpen(false);

  const handleDeleteOpen = (row) => {
    setRowId(row.jp_id);
    setDeleteOpen(true);
  };

  const handleSortOrder = (columnHeader) => {
    if (columnHeader === "Title") {
      setColumn("jp_title");
    }
    setSortOrder((prevValue) => (prevValue === "ASC" ? "DESC" : "ASC"));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setOffset((prevValue) => newPage * limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit((prev) => +event.target.value);
    setPage(0);
  };

  const {
    mutate: update,
    isSuccess: isUpdateSuccess,
    isError: isUpdateFailed
  } = useUpdateCandidate(rowId, onUpdateSuccess, onUpdateError, onUpdateSettled);

  const {
    data: candidates,
    refetch: refetchCandidates,
    isFetching: isCandidateFetching
  } = useGetCandidates({
    sortOrder: sortOrder,
    column,
    limit,
    offset,
    searchTerm: searchTerm,
    exp: experience,
    update: isUpdateSuccess,
    location: location.map(({ id, label }) => label).join(","),
    skills: skill.map(({ id, label }) => label).join(","),
    rowId,
    isUpdateSuccess
  });

  const { data: candidate } = useGetCandidate(rowId, "", isUpdateSuccess);

  function onUpdateSuccess(res) {
    setSnackBar((prevState) => ({ ...prevState, updateSuccess: true }));
    setRowId(null);
  }

  function onUpdateError(res) {
    setSnackBar((prevState) => ({ ...prevState, updateFail: true }));
  }

  function onUpdateSettled() {
    handleEditClose();
  }

  function onDeleteSuccess() {
    setSnackBar((prevState) => ({ ...prevState, deleteSuccess: true }));
    handleDeleteClose();
    refetchCandidates();
  }

  function onDeleteFailed() {
    setSnackBar((prevState) => ({ ...prevState, deleteFail: true }));
  }

  const {
    mutate: removeCandidate,
    isSuccess: isDeleteSuccess,
    isPending: isDeleting,
    isError: isDeleteFailed
  } = useRemoveCandidate(rowId, onDeleteSuccess, onDeleteFailed);

  return (
    <ListWrapper
      editForm={
        candidate && (
          <AddCandidateForm
            mutationFn={update}
            preloadedData={candidate}
            title={"Edit Candidate Details"}
          />
        )
      }
      editTitle={"Edit Candidate"}
      editOpen={editOpen}
      searchlabel={"Search By Candidate Name"}
      handleEditClose={() => setEditOpen(false)}
      onChangeSearch={(searchValue) => setSearchTerm(searchValue)}
      deleteLoadingState={isDeleting}
      onSearch={refetchCandidates}
      alertTitle={"Are you sure you want to delete this candidate?"}
      alertMessage={"You can undo this action by navigating to the Delete Page"}
      alertOpen={deleteOpen}
      alertCloseAction={handleDeleteClose}
      alertAcceptAction={() => {
        removeCandidate({ id: rowId });
      }}
    >
      {isCandidateFetching && <MatxLoading />}
      {isDeleteSuccess && (
        <ResponseSnackbar
          message={"Candidate deleted successfully"}
          open={snackBar.deleteSuccess}
          onClose={handleSnackbarClose}
          severity={"success"}
        />
      )}
      {isUpdateSuccess && (
        <ResponseSnackbar
          message={"Candidate details updated successfully"}
          open={snackBar.updateSuccess}
          onClose={handleSnackbarClose}
          severity={"success"}
        />
      )}
      {isUpdateFailed && (
        <ResponseSnackbar
          message={"Update failed"}
          open={snackBar.updateFail}
          onClose={handleSnackbarClose}
          severity={"error"}
        />
      )}
      {isDeleteFailed && (
        <ResponseSnackbar
          message={"Delete failed. Please try again later!"}
          open={snackBar.deleteFail}
          onClose={handleSnackbarClose}
          severity={"error"}
        />
      )}
      {user.role === "SA" && candidates && (
        <CandidateFilter
          selectedSkill={skill}
          selectedLocations={location}
          locations={States}
          experience={experience}
          setExperience={setExp}
          setLocation={(location) =>
            setLocation(typeof location === "string" ? location.split(",") : location)
          }
          setSkill={(skill) => setSkill(typeof skill === "string" ? skill.split(",") : skill)}
        />
      )}
      {candidate && detailOpen && (
        <CustomDialog
          open={true}
          onClose={() => setDetailOpen(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                maxWidth: "800px"
              }
            }
          }}
        >
          <CandidateDetail candidate={candidate} />
        </CustomDialog>
      )}
      {candidates && (
        <PaginationTable
          columnHeaders={Object.keys(CandidateTableHeaders)}
          columnSort={["Name"]}
          count={candidates.count}
          data={candidates.rows.map(
            ({ jp_id, jp_candidate_name, jp_contactno, jp_active, jp_state }) => ({
              jp_id,
              jp_name: jp_candidate_name,
              jp_contactno,
              jp_active: jp_active ? "True" : "False",
              jp_state
            })
          )}
          rowsPerPage={limit}
          currentPage={page}
          sortOrder={sortOrder}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSortOrder={handleSortOrder}
          actions={[
            {
              icon: <Visibility style={{ color: "#01579b" }} />,
              action: (candidate) => {
                setDetailOpen(true);
                setRowId(candidate.jp_id);
              }
            },
            {
              icon: <Edit style={{ color: "#01579b" }} />,
              action: handleEditAction
            },
            {
              icon: <Delete style={{ color: "#ff1744" }} />,
              action: handleDeleteOpen
            }
          ]}
        />
      )}
    </ListWrapper>
  );
}

export default CandidateList;
