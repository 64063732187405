export const Stream = [
  { id: 1, label: "Science (PCM, PCB, PCMB etc)" },
  { id: 2, label: "Arts (History, Geography, Philosophy etc)" },
  { id: 3, label: "Commerce (Accountancy, Statistics, Business etc)" },
  { id: 4, label: "Vocational (Hospitality, Tourism, Retail etc)" }
];

export const Skills = [
  { id: 0, label: "Android Development" },
  { id: 1, label: "Supply Chain Management" },
  { id: 2, label: "UX Designer" },
  { id: 3, label: "React Developer" },
  { id: 4, label: "Customer Service" },
  { id: 5, label: "Sales Support" },
  { id: 6, label: "Project Management" },
  { id: 7, label: "Content Writing" },
  { id: 8, label: "Cloud Computing" },
  { id: 9, label: "AI/ML Developer" },
  { id: 10, label: "Blockchain Developer" },
  { id: 11, label: "3D Artist" },
  { id: 12, label: "iOS Developer" },
  { id: 13, label: "SEO Developer" },
  { id: 14, label: "Video Game Developer" },
  { id: 15, label: "Dev Ops" },
  { id: 16, label: "CAD Design" },
  { id: 17, label: "Research" },
  { id: 18, label: "Linux Administrator" },
  { id: 19, label: "Full Stack Software Developer" },
  { id: 21, label: "Cost Accounting" },
  { id: 22, label: "Data Scientist" },
  { id: 23, label: "Flutter Developer" },
  { id: 24, label: "System's Engineer" },
  { id: 25, label: "Network Engineer" },
  { id: 26, label: "2D Artist" },
  { id: 27, label: "Writer" },
  { id: 28, label: "Hindi" },
  { id: 29, label: "Chinese" },
  { id: 30, label: "French" },
  { id: 31, label: "AWS Engineer" },
  { id: 32, label: "Microsoft Azure Engineer" },
  { id: 33, label: "VFX Creative Director" },
  { id: 34, label: "Narrative Designer" },
  { id: 35, label: "Software Tester" },
  { id: 36, label: "Automation Engineer" }
];

export const States = [
  { id: 0, label: "ANDAMAN AND NICOBAR ISLANDS" },
  { id: 1, label: "ANDHRA PRADESH" },
  { id: 2, label: "ARUNACHAL PRADESH" },
  { id: 3, label: "ASSAM" },
  { id: 4, label: "BIHAR" },
  { id: 5, label: "CHANDIGARH" },
  { id: 6, label: "CHHATTISGARH" },
  { id: 7, label: "DADRA AND NAGAR HAVELI" },
  { id: 8, label: "DAMAN AND DIU" },
  { id: 9, label: "DELHI" },
  { id: 10, label: "GOA" },
  { id: 11, label: "GUJARAT" },
  { id: 12, label: "HARYANA" },
  { id: 13, label: "HIMACHAL PRADESH" },
  { id: 14, label: "JAMMU AND KASHMIR" },
  { id: 15, label: "JHARKHAND" },
  { id: 16, label: "KARNATAKA" },
  { id: 17, label: "KERALA" },
  { id: 18, label: "LADAKH" },
  { id: 19, label: "LAKSHADWEEP" },
  { id: 20, label: "MADHYA PRADESH" },
  { id: 21, label: "MAHARASHTRA" },
  { id: 22, label: "MANIPUR" },
  { id: 23, label: "MEGHALAYA" },
  { id: 24, label: "MIZORAM" },
  { id: 25, label: "NAGALAND" },
  { id: 26, label: "ODISHA" },
  { id: 27, label: "PUDUCHERRY" },
  { id: 28, label: "PUNJAB" },
  { id: 29, label: "RAJASTHAN" },
  { id: 30, label: "SIKKIM" },
  { id: 31, label: "TAMIL NADU" },
  { id: 32, label: "TELANGANA" },
  { id: 33, label: "TRIPURA" },
  { id: 34, label: "UTTARAKHAND" },
  { id: 35, label: "UTTAR PRADESH" },
  { id: 36, label: "WEST BENGAL" }
];
