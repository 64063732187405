import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Box,
  CardMedia,
  Typography,
  IconButton
} from "@mui/material";
import { convertToBase64, convertISODateTimeToDate } from "app/utils/utils";
import NoCover from "../../../../assets/nocover.png";
import ReadMoreText from "app/components/ReadMoreText";
import { Close, Email, Language, Phone } from "@mui/icons-material";
import IconText from "app/views/profile/shared/IconText";
function CompanyDetail({ company, onClose }) {
  const logo = company.jp_logo || "";
  const cover = company.jp_cover_photo
    ? `data:image/jpeg;base64,${convertToBase64(company.jp_cover_photo.data)}`
    : NoCover;
  return (
    <Card sx={{ minWidth: 364, backgroundColor: "lightGray" }} style={{ borderRadius: "0" }}>
      <CardHeader
        style={{ backgroundColor: "#222a45", color: "white" }}
        avatar={<Avatar src={`data:image/jpeg;base64,${convertToBase64(logo.data)}`} />}
        titleTypographyProps={{ fontSize: "18px", fontWeight: "bold" }}
        title={company.jp_company_name}
        subheaderTypographyProps={{ color: "white" }}
        subheader={`Founded: ${convertISODateTimeToDate(company.jp_founded)}`}
        action={
          <IconButton aria-label="close" onClick={onClose} style={{ color: "white" }}>
            <Close />
          </IconButton>
        }
      />
      <CardMedia
        component={"img"}
        height={"194"}
        image={cover}
        style={{ objectFit: `${company.jp_cover_photo ? "cover" : "contain"}` }}
      />
      <CardContent style={{ backgroundColor: "whitesmoke" }}>
        <Typography variant="subtitle1" fontWeight={"bold"}>
          About {company.jp_company_name}
        </Typography>
        <ReadMoreText text={company.jp_about_company} maxChars={200} />
        <Box mt={2} display={"flex"} flexDirection={"column"} gap={2}>
          <IconText icon={<Email />} content={company.jp_email} />
          {company.jp_website && <IconText icon={<Language />} content={company.jp_website} />}
          {company.jp_contact_no && <IconText icon={<Phone />} content={company.jp_contact_no} />}
        </Box>
      </CardContent>
    </Card>
  );
}

export default CompanyDetail;
