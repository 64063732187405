import { useMutation } from "@tanstack/react-query";
import { updateCompany } from "app/api/company_api";

export const useUpdateCompany = ({ id, onSuccess = () => {}, onError, onSettled = () => {} }) => {
  return useMutation({
    mutationFn: (params) => updateCompany(params.company, id),
    onSuccess: (resData) => onSuccess(resData),
    onError: (error) => {
      onError(error);
    },
    onSettled: () => onSettled()
  });
};
