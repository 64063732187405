import React, { useState } from "react";
import PaginationTable from "../material-kit/tables/PaginationTable";
import ListWrapper from "app/components/ListWrapper";
import AddCompanyForm from "./shared/AddCompanyForm";
import { useDeleteCompany } from "app/hooks/company/useDeleteCompany";
import { useGetCompanies } from "app/hooks/company/useGetCompanies";
import { useGetCompany } from "app/hooks/company/useGetCompany";
import { useUpdateCompany } from "app/hooks/company/useUpdateCompany";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import CompanyDetail from "./shared/CompanyDetail";
import { Dialog, DialogContent } from "@mui/material";
import Loading from "app/components/MatxLoading";
import ResponseSnackbar from "app/components/ResponseSnackbar";
const TableHeaders = {
  Company: "jp_company_name",
  State: "jp_state",
  Industry: "jp_industry",
  Email: "jp_email",
  View: "View",
  Edit: "Edit",
  Delete: "Delete"
};
function CompanyList(props) {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [response, setResponse] = useState({
    success: false,
    error: false,
    message: ""
  });

  // For server side pagination
  const [sortOrder, setSortOrder] = useState("DESC");
  const [column, setColumn] = useState();
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState();

  // Search
  const [searchTerm, setSearchTerm] = useState("");

  const handleSortOrder = (columnHeader) => {
    if (Object.keys(TableHeaders).includes(columnHeader)) {
      setColumn(TableHeaders[columnHeader]);
    }
    setSortOrder((prevValue) => (prevValue === "ASC" ? "DESC" : "ASC"));
  };

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    setOffset((prevValue) => newPage * limit);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(+event.target.value);
    setPage(0);
  };

  const handleEditAction = (candidate) => {
    setSelectedRowId(candidate.jp_id);
    setEditOpen(true);
  };

  const handleDeleteOpen = (row) => {
    setSelectedRowId(row.jp_id);
    setDeleteOpen(true);
  };

  const {
    mutate: updateCompanyDetails,
    isPending: isUpdating,
    isSuccess: isUpdateSuccess,
    status: updateStatus
  } = useUpdateCompany({
    id: selectedRowId,
    onSuccess: () =>
      setResponse((prev) => ({ ...prev, success: true, message: "Updated successfully" })),
    onSettled: () => setEditOpen(false)
  });

  const {
    mutate: removeCompany,
    status: deleteStatus,
    isPending: isDeleting
  } = useDeleteCompany({
    id: selectedRowId,
    onSuccess: () => {
      setDeleteOpen(false);
      setSelectedRowId(null);
      setResponse({ success: true, error: false, message: "Company deleted successfully" });
      refetchCompanies();
    }
  });

  const { data: companies, refetch: refetchCompanies } = useGetCompanies({
    sortOrder,
    column,
    limit: limit,
    offset,
    refetch: [isUpdating, isDeleting, deleteStatus, isUpdateSuccess],
    searchTerm: searchTerm
  });

  const { data: company } = useGetCompany({ id: selectedRowId, isUpdating: isUpdating });

  return (
    <ListWrapper
      editForm={
        company && (
          <AddCompanyForm
            mutationFn={updateCompanyDetails}
            preloadedData={company}
            mutationState={isUpdating}
            title={"Edit Company Details"}
          />
        )
      }
      editTitle={"Edit Company"}
      editOpen={editOpen}
      searchlabel={"Search By Company Name"}
      handleEditClose={() => setEditOpen(false)}
      handleEditAction={updateCompanyDetails}
      onChangeSearch={(searchValue) => setSearchTerm(searchValue)}
      onSearch={refetchCompanies}
      alertTitle={"Are you sure you want to delete this company?"}
      alertMessage={"This action is not permanent and can be undone."}
      alertOpen={deleteOpen}
      alertCloseAction={() => setDeleteOpen(false)}
      alertAcceptAction={removeCompany}
    >
      {isUpdating || isDeleting ? <Loading /> : ""}
      {company && (
        <Dialog open={detailOpen} onClose={() => setDetailOpen(false)} sx={{ padding: 0 }}>
          <DialogContent sx={{ padding: 0 }}>
            <CompanyDetail company={company} onClose={() => setDetailOpen(false)} />
          </DialogContent>
        </Dialog>
      )}
      {response.success && (
        <ResponseSnackbar
          open={true}
          onClose={() => setResponse((prev) => ({ ...prev, success: false }))}
          message={response.message}
        />
      )}
      {response.error && (
        <ResponseSnackbar
          open={true}
          onClose={() => setResponse((prev) => ({ ...prev, error: false }))}
          message={response.message}
        />
      )}
      {companies && (
        <PaginationTable
          columnHeaders={Object.keys(TableHeaders)}
          count={companies.count || 0}
          columnSort={["Company"]}
          data={companies.data.map(
            ({ jp_id, jp_company_name, jp_state, jp_industry, jp_email }) => ({
              jp_id,
              jp_company_name,
              jp_state,
              jp_industry,
              jp_email
            })
          )}
          rowsPerPage={limit}
          currentPage={page}
          sortOrder={sortOrder}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSortOrder={handleSortOrder}
          actions={[
            {
              icon: <Visibility style={{ color: "#01579b" }} />,
              action: (company) => {
                setDetailOpen(true);
                setSelectedRowId(company.jp_id);
              }
            },
            {
              icon: <Edit style={{ color: "#01579b" }} />,
              action: handleEditAction
            },
            {
              icon: <Delete style={{ color: "#ff1744" }} />,
              action: handleDeleteOpen
            }
          ]}
        />
      )}
    </ListWrapper>
  );
}

export default CompanyList;
