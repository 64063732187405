import React, { useState } from "react";
import PaginationTable from "../material-kit/tables/PaginationTable";
import PostJobForm from "./shared/PostJobForm";
import useGetJobs from "app/hooks/job/useGetJobs";
import useGetJob from "app/hooks/job/useGetJob";
import useDeleteJob from "app/hooks/job/useDeleteJob";
import ListWrapper from "app/components/ListWrapper";
import { useUpdateJob } from "app/hooks/job/useUpdateJob";
import JobDetailDialog from "./shared/JobDetailDialog";
import useAuth from "app/hooks/useAuth";
import CustomDialog from "app/components/CustomDialog";
import ApplyDialogContent from "./shared/ApplyDialogContent";
import useGetCompanyJobs from "app/hooks/job/useGetCompanyJobs";
import { useGetTableActions as useGetRowActions } from "./hooks/useGetTableActions";
import { useGetJobTableHeaders } from "./hooks/useGetJobTableHeaders";
import { useExtractJobData } from "./hooks/useExtractTableData";
import ResponseSnackbar from "app/components/ResponseSnackbar";
import { useQueryClient } from "@tanstack/react-query";
import { DialogContent, Box } from "@mui/material";
import useGetCandidateApplication from "app/hooks/candidate/useGetCandidateApplication";
import FilterByType from "./shared/Job Filters/FilterByType";
import { useReducer } from "react";
import { actionTypes, initialState, paginationReducer } from "./shared/Reducer/PaginationReducer";
import useCheckJobsData from "./hooks/useCheckJobsData";
import { filterAction, filterReducer, filterState } from "./shared/Reducer/FilterReducer";
import FilterByExp from "./shared/Job Filters/FilterByExp";
import FilterByLocation from "./shared/Job Filters/FilterByLocation";

function JobListings(props) {
  const { user } = useAuth();
  const [dialogState, setDialogState] = useState({
    editOpen: false,
    deleteOpen: false,
    detailOpen: false,
    applyOpen: false
  });

  const [pagination, dispatchPagination] = useReducer(paginationReducer, initialState);
  const [selectedRowId, setSelectedRowId] = useState();
  const [responseState, setResponseState] = useState({
    error: false,
    success: false,
    message: ""
  });

  // For job filtering
  const [filter, dispatchFilter] = useReducer(filterReducer, filterState);

  const queryClient = useQueryClient();

  const handleOpenDialog = (row, type) => {
    setSelectedRowId(row.jp_id);
    setDialogState((prevState) => ({ ...prevState, [type]: true }));
  };

  const handleCloseDialog = () => {
    setDialogState({ editOpen: false, deleteOpen: false, detailOpen: false, applyOpen: false });
  };

  const handleSortOrder = (columnHeader) => {
    if (columnHeader === "Title") {
      dispatchPagination({ type: actionTypes.SET_COLUMN, payload: "jp_title" });
    }
    dispatchPagination({ type: actionTypes.SET_SORT_ORDER });
  };

  const handleChangePage = (_, newPage) => {
    dispatchPagination({ type: actionTypes.SET_PAGE, payload: newPage });
    dispatchPagination({ type: actionTypes.SET_OFFSET, payload: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatchPagination({ type: actionTypes.SET_LIMIT, payload: +event.target.value });
  };

  // Update job details
  const { mutate: mutateJob, isPending: isUpdating } = useUpdateJob({
    id: selectedRowId,
    onSuccess: (data) => {
      setSelectedRowId(undefined);
      queryClient.invalidateQueries({ queryKey: ["get_single_job"] });
      queryClient.invalidateQueries({ queryKey: ["job_listings"] });
      setResponseState({
        error: false,
        success: true,
        message: "Job post updated successfully"
      });
    },
    onError: () => {
      setResponseState({ error: true, success: false, message: "Error updating job post" });
    },
    onSettled: () => setDialogState((prevState) => ({ ...prevState, editOpen: false }))
  });

  // Get jobs posted by company
  const { data: jobsByCompany, isFetched: isCompanyJobsFetched } = useGetCompanyJobs({
    sortOrder: pagination.sortOrder,
    column: "",
    limit: pagination.limit,
    offset: pagination.offset,
    id: user.id,
    selectedRowId,
    searchQuery: pagination.searchTerm,
    isUpdating
  });

  // Get all jobs posted
  const {
    data: allJobs,
    isFetching,
    isFetched: isJobsFetched
  } = useGetJobs({
    sortOrder: pagination.sortOrder,
    column: "",
    limit: pagination.limit,
    offset: pagination.offset,
    searchTerm: pagination.searchTerm,
    isUpdating,
    type: filter.type,
    exp: filter.exp,
    location: filter.location
  });

  // Get jobs applied by the candidate (enabled only for candidate login)
  const { data: appliedJobs } = useGetCandidateApplication({
    id: user.id,
    debounceSearch: "",
    status: [
      { value: "pending" },
      { value: "shortlisted" },
      { value: "accepted" },
      { value: "rejected" }
    ],
    enabled: user.role === "candidate"
  });

  // Get a single job detail
  const { data: selectedJob, isSuccess: isSelectedJob } = useGetJob(selectedRowId, isUpdating);

  // Delete a job
  const { mutate: deleteJob, isPending: isDeleting } = useDeleteJob({
    id: selectedRowId,
    updateDelete: { jp_is_deleted: true },
    onSuccess: () => {
      setDialogState((prevState) => ({ ...prevState, deleteOpen: false }));
      setSelectedRowId(undefined);
      setResponseState((prevValue) => ({
        ...prevValue,
        success: true,
        message: "Job post deleted successfully"
      }));
    },
    onError: () => {
      setResponseState((prevValue) => ({ ...prevValue, error: true, message: "Error deleting" }));
    },
    onSettled: () => {
      setDialogState((prevState) => ({ ...prevState, deleteOpen: false }));
    }
  });

  // Row Actions are the actions at the end of each row like 'Edit', 'Delete', 'View' etc
  const RowActions = useGetRowActions(handleOpenDialog);

  // Checking to see if joblisting or jobs for a company has been fetched
  const { tableData, count } = useCheckJobsData(
    isJobsFetched,
    isCompanyJobsFetched,
    allJobs,
    jobsByCompany
  );

  // Data shown to Company in the Job Listing table is different than for Admin and Candidate
  const extractedData = useExtractJobData(tableData, appliedJobs || []);

  // Cloumn Headers for Job Listing table like Job Title, Company, Experience etc
  const ColumnHeaders = useGetJobTableHeaders();

  console.log("experience", filter.location);
  return (
    <ListWrapper
      editForm={
        isSelectedJob && (
          <PostJobForm
            mutationFn={mutateJob}
            preloadedData={selectedJob}
            title={"Edit Job Details"}
          />
        )
      }
      editTitle={"Edit Job"}
      editOpen={dialogState.editOpen}
      handleEditClose={() => setDialogState((prevState) => ({ ...prevState, editOpen: false }))}
      onChangeSearch={(searchValue) =>
        dispatchPagination({ type: actionTypes.SET_SEARCH_TERM, payload: searchValue })
      }
      searchlabel={"Search By Job Title"}
      alertTitle={"Are you sure you want to delete this job post"}
      alertMessage={"This action is not permanent. You can undo this action in the 'Trash' page."}
      alertOpen={dialogState.deleteOpen}
      deleteLoadingState={isDeleting}
      alertCloseAction={() => setDialogState((prevState) => ({ ...prevState, deleteOpen: false }))}
      alertAcceptAction={deleteJob}
    >
      {/** Job Detail Dialog */}
      {selectedJob && (
        <JobDetailDialog
          open={dialogState.detailOpen}
          handleClose={handleCloseDialog}
          jobPosting={selectedJob}
        />
      )}
      <ResponseSnackbar
        open={responseState.success}
        message={responseState.message}
        name={"success"}
        severity={"success"}
        onClose={() => setResponseState({ error: false, success: false, message: "" })}
      />
      <ResponseSnackbar
        open={responseState.error}
        message={responseState.message}
        name={"error"}
        onClose={() => setResponseState({ error: false, success: false, message: "" })}
        severity={"error"}
      />
      {selectedJob && (
        <CustomDialog
          dialogTitle={`Apply for ${selectedJob.jp_title}`}
          fullWidth
          onClose={handleCloseDialog}
          open={dialogState.applyOpen}
        >
          <DialogContent>
            <ApplyDialogContent job={selectedJob} onClose={handleCloseDialog} />
          </DialogContent>
        </CustomDialog>
      )}
      <Box
        sx={{
          display: "flex",
          backgroundColor: "beige",
          px: 2,
          mt: 2,
          borderRadius: 2,
          height: "200px"
        }}
      >
        <FilterByType
          onChecked={(type) => dispatchFilter({ type: filterAction.SET_TYPE, payload: type })}
        />
        <FilterByExp
          value={filter.exp}
          onChecked={(exp) => dispatchFilter({ type: filterAction.SET_EXP, payload: exp })}
        />
        <FilterByLocation
          onChecked={(location) =>
            dispatchFilter({ type: filterAction.SET_LOCATION, payload: location })
          }
        />
      </Box>
      {!isFetching && extractedData && (
        <PaginationTable
          data={extractedData}
          count={count}
          columnHeaders={ColumnHeaders}
          columnSort={["Title"]}
          rowsPerPage={pagination.limit}
          currentPage={pagination.page}
          sortOrder={pagination.sortOrder}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSortOrder={handleSortOrder}
          actions={RowActions}
        />
      )}
    </ListWrapper>
  );
}

export default JobListings;
