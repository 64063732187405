import { useState } from "react";
import { createContext } from "react";

export const ErrorContext = createContext({ error: false, message: "", severity: "" });

const ErrorContextProvider = ({ children }) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const setSuccessState = (newSuccess, message, severity = "success") => {
    setSuccess(true);
    setError(false);
    setMessage(message);
    setSeverity(severity);
  };

  const setErrorState = (newError, newMessage, newSeverity = "info") => {
    setError(newError);
    setMessage(newMessage);
    setSeverity(newSeverity);
  };

  const clearErrorState = () => {
    setError(false);
    setSuccess(false);
    setMessage("");
    setSeverity("");
  };
  return (
    <ErrorContext.Provider
      value={{ success, error, message, severity, setSuccessState, setErrorState, clearErrorState }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
