import { Close } from "@mui/icons-material";
import { DialogContent, DialogTitle, IconButton, Typography, styled, Dialog } from "@mui/material";
import React from "react";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}));
function TCDialog({ open, handleClose }) {
  const AppName = "Reyan Job Portal";
  return (
    <BootstrapDialog open={open} aria-labelledby="terms and condition dialog" onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2 }}>Terms and Condition (Updated On: 16-07-2024)</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <DialogContent>
        <Typography gutterBottom>
          Welcome to {AppName}. By using our website, you agree to the following terms and
          conditions:
        </Typography>
        <Typography gutterBottom>
          <strong>Use of site: </strong> You agree to use the site only for lawful purposes and in a
          manner that does not infringe the rights of, restrict, or inhibit anyone else's use and
          enjoyment of the site.
        </Typography>
        <Typography gutterBottom>
          <strong>Account Security:</strong> You are responsible for maintaining the confidentiality
          of your account and password and for all activities that occur under your account. You
          agree to notify us immediately of any unauthorized use of your account.
        </Typography>
        <Typography gutterBottom>
          <strong>Content Posting:</strong> You agree that any content you post on the site,
          including job listings, resumes, and comments, does not violate any laws or rights of
          third parties. {AppName} reserves the right to remove any content deemed inappropriate.
        </Typography>
        <Typography gutterBottom>
          <strong>Data Accuracy:</strong> You are responsible for ensuring that any information you
          provide is accurate, complete, and up-to-date. {AppName} does not guarantee the accuracy
          or completeness of any user-generated content.{" "}
        </Typography>
        <Typography gutterBottom>
          <strong>Privacy:</strong> Your use of the site is also governed by our Privacy Policy. By
          using the site, you consent to the collection, use, and sharing of your information as
          described in our Privacy Policy.
        </Typography>
        <Typography gutterBottom>
          <strong>Liability: </strong> {AppName} is not liable for any loss or damage resulting from
          your use of the site or reliance on any information provided on the site. This includes,
          but is not limited to, loss or damage from business interruptions or data loss.
        </Typography>
        <Typography gutterBottom>
          <strong>Prohibited Activities:</strong> You agree not to engage in any activity that could
          harm the site or its users, including but not limited to spamming, hacking, uploading
          malicious content, or posting false or misleading information.
        </Typography>
        <Typography gutterBottom>
          <strong>Modification of Terms:</strong> {AppName} reserves the right to modify these terms
          at any time. Your continued use of the site constitutes acceptance of the modified terms.
        </Typography>
        <Typography gutterBottom>
          <strong>Termination:</strong> {AppName} reserves the right to terminate your account at
          its sole discretion, with or without notice, for conduct that violates these terms or is
          otherwise harmful to other users of the site.
        </Typography>
        <Typography gutterBottom textAlign={"center"} mt={4} fontWeight={"bold"}>
          Your privacy is important to us. By using {AppName}, you agree to our collection and use
          of your data as described in our Privacy Policy.{" "}
        </Typography>
      </DialogContent>
    </BootstrapDialog>
  );
}

export default TCDialog;
