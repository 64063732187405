import React from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
function CustomDialog({
  fullWidth,
  children,
  maxWidth,
  open,
  onClose,
  dialogTitle,
  transition,
  ...rest
}) {
  return (
    <Dialog
      TransitionComponent={transition}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", top: 8, right: 8, ml: 2 }}
      >
        <Close />
      </IconButton>
      {children}
    </Dialog>
  );
}

export default CustomDialog;
