import { useMutation } from "@tanstack/react-query";
import { deleteCompany } from "app/api/company_api";

export const useDeleteCompany = ({ id, onSuccess, onError, onDelete }) => {
  return useMutation({
    mutationFn: (params) => {
      deleteCompany(id);
    },
    onSuccess: () => onSuccess()
  });
};
