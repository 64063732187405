import { useMutation } from "@tanstack/react-query";
import { updatePassword as updateAdminPassword } from "app/api/admin_api";

export const useUpdateAdminPass = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) => updateAdminPassword({ ...params }),
    onSuccess: onSuccess,
    onError: onError
  });
};
