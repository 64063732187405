import useAuth from "app/hooks/useAuth";
import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

function ProtectedRoute({ children, authRoles }) {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/session/login/candidate" />;
  }

  if (user && user.role.toUpperCase() === authRoles.admin) {
    return children;
  }

  if (user && authRoles.includes(user.role.toUpperCase())) {
    return children;
  } else {
    return <h1>Cannot access this page</h1>;
  }
}

export default ProtectedRoute;
