import axios from "axios";

export async function getJobBenefitList() {
  const benefits = await axios.get("/benefits");
  return benefits.data;
}

export const addBenefits = async (benefits) => {
  return await axios.post("/benefits/add-benefits", benefits);
};
