import React from "react";
import { Button } from "@mui/material";
function TableAction({ icon, action, text, ...rest }) {
  return (
    <Button onClick={action} {...rest} endIcon={icon} sx={{ flex: "none" }}>
      {text}
    </Button>
  );
}

export default TableAction;
