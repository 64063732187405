import { useTheme } from "@emotion/react";
import {
  Box,
  Table,
  styled,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  useMediaQuery,
  Typography
} from "@mui/material";
import { H4 } from "app/components/Typography";
import TableAction from "app/components/TableAction";
// STYLED COMPONENT
const StyledTable = styled(Table)(() => ({
  whiteSpace: "pre",
  "& thead": {
    "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } }
  },
  "& tbody": {
    "& tr": { "& td": { paddingLeft: 0 } }
  },
  width: "100%",
  minWidth: "max-content",
  tableLayout: "auto"
}));

export default function PaginationTable({
  data,
  count,
  sortOrder,
  columnHeaders,
  columnSort,
  currentPage,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleSortOrder,
  actions
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const headerStyle = { fontSize: "1rem" };
  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            {columnHeaders.map((header, index) => {
              if (index === 0) {
                return (
                  <TableCell key={`${header}_${index}`} align="left" sx={headerStyle}>
                    {header}
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={`${header}_${index}`} align="center" sx={headerStyle}>
                    {header}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.slice(0 * rowsPerPage, 0 * rowsPerPage + rowsPerPage).map((currentRow, index) => {
            return (
              <TableRow key={currentRow.jp_id}>
                {Object.values(currentRow).map((value, index) => {
                  if (index === 0 || index === 6) {
                    // we do not want to show the row id or jp_is_applied value
                    return;
                  }
                  return (
                    <TableCell key={`${value}_${index}`} align={index === 1 ? "left" : "center"}>
                      <Typography
                        noWrap
                        sx={{ overflow: "hidden", textOverflow: "ellipsis", maxWidth: "320px" }}
                      >
                        {value ? value.toString() : ""}
                      </Typography>
                    </TableCell>
                  );
                })}

                {actions &&
                  actions.map((action, index) => (
                    <TableCell key={`${action}_${index}`} align="center">
                      {action.text === "Apply" && currentRow.jp_is_applied ? (
                        <H4
                          sx={{
                            color: "green",
                            backgroundColor: "lightgreen",
                            padding: "4px 6px 4px 6px",
                            borderRadius: "5px",
                            fontWeight: 100,
                            width: "120px",
                            textAlign: "center"
                          }}
                          mx={"auto"}
                        >
                          Applied
                        </H4>
                      ) : (
                        <TableAction
                          key={`${currentRow.jp_id}_${index}`}
                          icon={action.icon}
                          text={action.text && !isSmallScreen ? action.text : ""}
                          variant={action.variant || ""}
                          style={action.style || {}}
                          color={action.color}
                          action={() => {
                            console.log("action", action.id);
                            action.action(currentRow, action.id);
                          }}
                        />
                      )}
                    </TableCell>
                  ))}
              </TableRow>
            );
          })}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={currentPage}
        component="div"
        rowsPerPage={rowsPerPage}
        count={count}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
