import { getCompanyId } from "app/api/company_api";
import { useMutation } from "@tanstack/react-query";

const useCheckCompanyExists = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: (params) => getCompanyId(params.email),
    onSuccess: onSuccess,
    onError: onError
  });
};

export default useCheckCompanyExists;
