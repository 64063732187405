import ContentBox from "app/views/jobs/shared/ContentBox";
import { Avatar, Box, Stack, Grid, InputAdornment, Tooltip } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  convertToBase64,
  createObjectURLFromFile,
  getCurrentTimeStamp,
  isFileLimitExceeded
} from "app/utils/utils";
import FormTextField from "app/components/form-components/FormTextField";
import FormAutocomplete from "app/components/form-components/FormAutocomplete";
import FormDatePicker from "app/components/form-components/FormDatePicker";
import { Industries, Countries } from "app/utils/selection_constants";
import dayjs from "dayjs";
import FormUploadFile from "app/components/form-components/FormUploadFile";
import { LoadingButton } from "@mui/lab";
import Send from "@mui/icons-material/Send";
import { Span } from "app/components/Typography";
import useAuth from "app/hooks/useAuth";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import { convertListToString } from "app/utils/utils";
import {
  EmailRegex,
  filterAlphabets,
  isValidURL,
  NameRegex,
  PhoneRegex,
  validateDate
} from "app/utils/constant";
import UploadFileTitle from "app/views/candidate/shared/UploadFileTitle";
import PreviewLogo from "./PreviewLogo";
import FormAutocompleteMulti from "app/components/form-components/FormAutocompleteMulti";
import { InfoRounded } from "@mui/icons-material";

function AddCompanyForm({ mutationFn, preloadedData, title = "", mutationState }) {
  const location = useLocation();
  const isEditAction =
    location.pathname.includes("company/listings") || location.pathname.includes("/profile");

  const [logo, setLogo] = useState(preloadedData ? preloadedData.jp_logo : null);

  const previewLogo =
    logo instanceof File
      ? createObjectURLFromFile(logo)
      : `data:image/jpeg;base64,${convertToBase64(logo?.data)}`;

  const [cover, setCover] = useState(preloadedData ? preloadedData.jp_cover_photo : null);
  const previewCover =
    cover instanceof File
      ? createObjectURLFromFile(cover)
      : `data:image/jpeg;base64,${convertToBase64(cover?.data)}`;

  const [showLogoPreview, setShowLogoPreview] = useState(false);
  const [showCoverPreview, setShowCoverPreview] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const { user } = useAuth();

  const handleUploadLogo = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 10 })) {
      alert("File size limit of 10KB exceeded");
      setError(
        "jp_logo",
        { type: "custom", message: "File size limit of 10KB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors();
      setLogo(file);
    }
  };

  const handleUploadCover = (file) => {
    if (isFileLimitExceeded({ fileSize: file.size, limit: 100 })) {
      alert("File size limit of 100KB exceeded");
      setError(
        "jp_cover_photo",
        { type: "custom", message: "File size limit of 100KB exceeded" },
        { shouldFocus: true }
      );
    } else {
      clearErrors();
      setCover(file);
    }
  };

  const onSubmitData = (data, event) => {
    const fd = new FormData(event.target);
    fd.set("jp_country", convertListToString(data.jp_country));
    fd.set("jp_state", data.jp_state);
    fd.set(
      "jp_industry",
      typeof data.jp_industry === "string" ? data.jp_industry : data.jp_industry.label
    );

    if (data.jp_website) {
      fd.set("jp_website", `https://${data.jp_website}`);
    }

    if (location.pathname.includes("/company/addCompany")) {
      fd.set("jp_password", "password123$");
      fd.set("jp_created_at", getCurrentTimeStamp());
      fd.set("jp_created_by", user.id);
    }
    if (location.pathname.includes("/company/listings")) {
      fd.set("jp_updated_at", getCurrentTimeStamp());
      fd.set("jp_updated_by", user.id);
    }
    if (data.jp_founded.toString() !== "Invalid Date") {
      fd.set("jp_founded", data.jp_founded.toISOString());
    } else {
      fd.delete("jp_founded");
    }

    mutationFn({ company: fd });
  };

  // If the company belongs to a non-standard industry category,
  // we need to include this industry as an option in the dropdown select.
  // Otherwise, the industry text field will remain empty.
  const IndustryOptions = useMemo(() => {
    const options = Industries;
    if (preloadedData) {
      const preloadedIndustry = preloadedData.jp_industry;
      if (preloadedIndustry) {
        options.push({ id: options.length + 1, label: preloadedIndustry });
      }
    }
    return options;
  }, [preloadedData]);

  return (
    <ContentBox>
      <h1>{title}</h1>
      {logo && (
        <PreviewLogo open={showLogoPreview} onClick={() => setShowLogoPreview(false)}>
          <Avatar src={previewLogo} sx={{ width: "200px", height: "200px" }} />
        </PreviewLogo>
      )}
      {cover && (
        <PreviewLogo open={showCoverPreview} onClick={() => setShowCoverPreview(false)}>
          <img style={{ width: "400px", height: "200px" }} src={previewCover} alt={"cover"} />
        </PreviewLogo>
      )}
      <form onSubmit={handleSubmit(onSubmitData)} encType="multipart/form-data">
        <Stack direction={"row"} gap={4}>
          <Box sx={{ width: 400 }}>
            <UploadFileTitle
              title={"Upload Logo"}
              shouldPreview={previewLogo}
              actionTitle={isEditAction ? "View" : logo ? "Preview" : ""}
              previewClick={() => {
                if (preloadedData || logo) {
                  setShowLogoPreview(true);
                }
              }}
            />
            <FormUploadFile
              file={logo}
              register={register}
              fileType={"image/*"}
              name={"jp_logo"}
              errors={errors.jp_logo}
              onUploadFile={handleUploadLogo}
              helperText={logo ? null : "Upload company logo"}
            />
          </Box>
          <Box sx={{ width: 400 }}>
            <UploadFileTitle
              title={"Upload Cover"}
              actionTitle={isEditAction ? "View" : cover ? "Preview" : ""}
              shouldPreview={previewCover}
              previewClick={() => {
                if (preloadedData || logo) {
                  setShowCoverPreview(true);
                }
              }}
            />
            <FormUploadFile
              file={cover}
              register={register}
              fileType={"image/*"}
              name={"jp_cover_photo"}
              errors={errors.jp_cover_photo}
              onUploadFile={handleUploadCover}
              helperText={cover ? null : "Upload cover photo"}
            />
          </Box>
        </Stack>
        <h2>Add Company Name</h2>
        {/* Company */}
        <FormTextField
          control={control}
          name={"jp_company_name"}
          defaultValue={preloadedData ? preloadedData.jp_company_name : ""}
          label={"Company Name *"}
          fullWidth={true}
          helperText={"Company name is required"}
        />
        <h2>Address</h2>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Country */}
            <FormAutocompleteMulti
              control={control}
              name={"jp_country"}
              defaultValue={preloadedData && preloadedData.jp_country}
              label={"Country *"}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              values={Countries}
              sxValues={{ width: "100%" }}
              helperText={"Country is required"}
            />
          </Grid>
          <Grid item xs={6}>
            {/* City */}
            <FormTextField
              control={control}
              name={"jp_city"}
              defaultValue={preloadedData && preloadedData.jp_city}
              label={"City *"}
              rules={{ pattern: { value: NameRegex, message: "Please enter valid city name" } }}
              fullWidth
              helperText={"City is required"}
            />
          </Grid>
          <Grid item xs={6}>
            {/* State */}
            <FormTextField
              control={control}
              name={"jp_state"}
              defaultValue={preloadedData && preloadedData.jp_state}
              label={"State (comma separated for multiple states) *"}
              helperText={"State is required"}
              fullWidth
            />
          </Grid>
        </Grid>
        <h2>Contact</h2>
        <Box display={"flex"} gap={4} justifyContent={"space-between"}>
          {/* Website */}
          <FormTextField
            control={control}
            name={"jp_website"}
            defaultValue={preloadedData && preloadedData.jp_website}
            label={"Website link (https://www.example.com)"}
            rules={{ validate: isValidURL }}
            InputProps={{
              startAdornment: <InputAdornment position="start">https://</InputAdornment>
            }}
            fullWidth
          />
          {/* Email */}
          <FormTextField
            control={control}
            name={"jp_email"}
            defaultValue={preloadedData && preloadedData.jp_email}
            label={"Email *"}
            fullWidth
            rules={{ pattern: { value: EmailRegex, message: "Please enter a valid email" } }}
            helperText={"Email is required"}
          />
          <FormTextField
            control={control}
            name={"jp_contact_no"}
            defaultValue={preloadedData && preloadedData.jp_contact_no}
            label={"Phone Number *"}
            type={"text"}
            rules={{
              pattern: { value: PhoneRegex, message: "Please enter valid phone number" }
            }}
            inputProps={{ maxLength: 10 }}
            onInput={filterAlphabets}
            fullWidth
            helperText={"Phone number is required"}
          />
        </Box>
        <h2>About Company</h2>
        <Box display={"flex"} flexDirection={"column"} gap={3}>
          <Box display={"flex"} gap={4} justifyContent={"space-evenly"}>
            {/* Foundation Date */}
            <FormDatePicker
              control={control}
              name={"jp_founded"}
              defaultValue={preloadedData && dayjs(preloadedData.jp_founded)}
              label={"Foundation Date *"}
              disableFuture
              rules={{ validate: validateDate }}
              fullWidth
            />
            {/* Industry */}
            <FormAutocomplete
              control={control}
              name={"jp_industry"}
              freeSolo={true}
              defaultValue={preloadedData && preloadedData.jp_industry}
              sxValues={{ width: "100%" }}
              values={IndustryOptions}
              inputProps={{
                startAdornment: (
                  <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                    <Tooltip
                      title={
                        "If an industry isn't listed, you can manually enter its name without selecting an option"
                      }
                    >
                      <InfoRounded />
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              label={"Industry (IT, Texttile, Communication etc) *"}
              helperText={"Industry is required"}
            />

            {/* Social Media*/}
            <FormTextField
              control={control}
              name={"jp_social_media_links"}
              defaultValue={preloadedData && preloadedData.jp_social_media_links}
              fullWidth
              label={"Social media links (comma separated)"}
              helperText={false}
            />
          </Box>
          <FormTextField
            control={control}
            name={"jp_about_company"}
            label={"Add description about the company *"}
            helperText={"Company description is required"}
            defaultValue={preloadedData && preloadedData.jp_about_company}
            multiline
            rows={8}
          />
        </Box>
        <LoadingButton
          loading={mutationState}
          color="primary"
          variant="contained"
          type="submit"
          sx={{ marginTop: 2 }}
          startIcon={<Send />}
        >
          <Span>{title.includes("Edit") ? "Update" : "Add"}</Span>
        </LoadingButton>
      </form>
    </ContentBox>
  );
}

export default AddCompanyForm;
