import BootstrapDialog from "./BootstrapDialog";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Button
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Close from "@mui/icons-material/Close";
function VerifyOTPDialog({
  open,
  otp,
  otpError,
  resendState,
  verifyState,
  handleVerifyOTP,
  handleOTPChange,
  handleResendOTP,
  handleClose
}) {
  return (
    <BootstrapDialog open={open} handleClose={handleClose}>
      <DialogTitle>Verify Your Email</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
        aria-label="close"
      >
        <Close />
      </IconButton>
      <DialogContent>
        <DialogContentText>Please enter the OTP sent to your email address*</DialogContentText>
        <TextField
          autoFocus
          required
          margin="dense"
          id="otp"
          name="otp"
          label="OTP"
          fullWidth
          value={otp}
          FormHelperTextProps={{ sx: { color: "red" } }}
          helperText={otpError ? "*OTP does not match" : null}
          onChange={(event) => handleOTPChange(event.target.value)}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{ color: "gray" }}>
          Cancel
        </Button>
        <LoadingButton loading={resendState} onClick={handleResendOTP}>
          Resend OTP
        </LoadingButton>
        <LoadingButton variant={"outlined"} loading={verifyState} onClick={handleVerifyOTP}>
          Verify OTP
        </LoadingButton>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default VerifyOTPDialog;
