import { Box, Avatar, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import { H1, Paragraph } from "app/components/Typography";
import { convertToBase64 } from "app/utils/utils";
import StackChip from "./StackChip";
import { Fragment } from "react";
const QuickInfoChip = styled(Chip)({
  backgroundColor: "teal",
  color: "white"
});
const BorderBox = styled(Box)({
  borderRadius: "10px",
  border: "2px solid gray",
  padding: "10px"
});
function CandidateDetail({ candidate }) {
  let skills = [];
  let profile = "";
  let languages = [];
  let contact = {};
  let address = {};
  let education = {};

  if (candidate) {
    skills = candidate.Skills ? candidate.Skills.map(({ jp_id, jp_skill }) => jp_skill) : [];
    profile = convertToBase64(candidate.jp_photo ? candidate.jp_photo.data : "");
    languages = candidate.jp_language ? candidate.jp_language.split("| ") : [];
    contact = {
      "Contact Number": candidate.jp_contactno,
      Email: candidate.jp_email
    };
    address = {
      Address: candidate.jp_address,
      City: candidate.jp_city,
      State: candidate.jp_state
    };
    education = {
      Qualification: candidate.jp_qualification,
      Degree: candidate.jp_degree_name
    };
  }

  return (
    <Grid container justifyContent={"center"} alignItems={"center"} padding={2}>
      <Grid item alignSelf={"center"} justifySelf={"center"}>
        <Avatar
          sx={{ width: "200px", height: "200px" }}
          src={`data:image/jpeg;base64,${profile}`}
        />
        <H1 textAlign={"center"}>{candidate.jp_candidate_name}</H1>
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} flexDirection={"column"} mt={2}>
          <Paragraph>{candidate.jp_about_me}</Paragraph>
          <Box display={"flex"} my={2} gap={2} flexWrap={"wrap"}>
            <QuickInfoChip
              label={`Notice Period: ${candidate.jp_notice_period || "Not specified"}`}
            />
            <QuickInfoChip label={`Current CTC: ${candidate.jp_current_ctc || "Not specified"}`} />
            <QuickInfoChip label={`Designation: ${candidate.jp_designation || "Not specified"}`} />
            <QuickInfoChip label={`YOE: ${candidate.jp_yoe || "Not specified"}`} />
            <QuickInfoChip
              label={`Current Company: ${candidate.jp_current_company || "Not specified"}`}
            />
          </Box>

          <h3>Skills</h3>
          <BorderBox>
            {skills && (
              <StackChip
                direction={"row"}
                spacing={1}
                flexWrap={"wrap"}
                data={skills}
                rowGap={"10px"}
              />
            )}
          </BorderBox>
          <Box display={"flex"} gap={2}>
            {/* Education */}
            <Box maxWidth={"50%"}>
              <h3>Education</h3>
              <BorderBox>
                <Grid container>
                  {Object.entries(education).map((entry) => (
                    <Fragment key={`${entry[0]}`}>
                      <Grid item xs={6}>
                        <Paragraph>{entry[0]}: </Paragraph>
                      </Grid>
                      <Grid item xs={6} textAlign={"right"}>
                        <Paragraph fontWeight={"bold"}>{entry[1] || "Not mentioned"}</Paragraph>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </BorderBox>
            </Box>
            {/* Language Spoken */}
            <Box flexGrow={1}>
              <h3>Languages Spoken</h3>
              <BorderBox>
                {languages && (
                  <StackChip
                    direction={"row"}
                    spacing={1}
                    flexWrap={"wrap"}
                    data={languages}
                    rowGap={"10px"}
                  />
                )}
              </BorderBox>
            </Box>
          </Box>

          <Box display={"flex"} gap={4}>
            <Box flexGrow={1}>
              <h3>Contact Information</h3>
              <BorderBox>
                <Grid container>
                  {Object.entries(contact).map((entry) => {
                    return (
                      <Fragment key={entry[0]}>
                        <Grid item xs={6}>
                          <Paragraph>{entry[0]}: </Paragraph>
                        </Grid>
                        <Grid item xs={6} textAlign={"right"}>
                          <Paragraph fontWeight={"bold"} style={{ overflowWrap: "break-word" }}>
                            {entry[1] || "Not mentioned"}
                          </Paragraph>
                        </Grid>
                      </Fragment>
                    );
                  })}
                </Grid>
              </BorderBox>
            </Box>
            <Box flexGrow={1}>
              <h3>Location</h3>
              <BorderBox>
                <Grid container>
                  {Object.entries(address).map((entry) => (
                    <Fragment key={entry[0]}>
                      <Grid item xs={6}>
                        <Paragraph>{entry[0]}: </Paragraph>
                      </Grid>
                      <Grid item xs={6} textAlign={"right"}>
                        <Typography fontWeight={"bold"} style={{ overflowWrap: "break-word" }}>
                          {entry[1] || "Not mentioned"}
                        </Typography>
                      </Grid>
                    </Fragment>
                  ))}
                </Grid>
              </BorderBox>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default CandidateDetail;
