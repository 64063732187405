import { useQuery } from "@tanstack/react-query";
import { getApplicationOfCompany } from "app/api/application_api";
import useAuth from "../useAuth";

export const useGetApplicantsForCompany = ({ id, status, limit, offset, searchQuery }) => {
  const { user } = useAuth();
  return useQuery({
    queryKey: ["company_applicants", status, limit, offset, searchQuery],
    queryFn: () => getApplicationOfCompany({ id, status, limit, offset, searchQuery }),
    enabled: user.role === "company"
  });
};
