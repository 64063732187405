import axios from "axios";

export async function addCandidate(candidate) {
  return axios
    .post("/candidate", candidate, { headers: { "Content-Type": "multipart/form-data" } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 409) {
        throw Error(err.response.data.message);
      }
      throw Error(err);
    });
}

export async function getCandidates(
  sort,
  column,
  limit,
  offset,
  searchQuery,
  exp,
  location,
  skills,
  id
) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery || "",
    orderBy: column,
    orderByValue: sort,
    yoe: exp,
    location,
    skills,
    id: id
  };
  const response = await axios.get("/candidate", { params: queryParams });
  return response.data;
}

export async function getDeletedCandidates(sort, column, limit, offset, searchQuery, id) {
  const queryParams = {
    limit: limit,
    offset: offset,
    searchQuery: searchQuery || "",
    orderBy: column,
    orderByValue: sort,
    id: id
  };
  const response = await axios.get("/candidate/deleted", { params: queryParams });
  return response.data;
}

export async function getCandidate(id) {
  const queryParams = {
    id: id
  };
  const response = await axios.get("/candidate", {
    params: queryParams
  });
  return response.data;
}

export async function deleteCandidate(id) {
  return axios.patch("/candidate/" + id, { jp_is_deleted: true }).catch((err) => {
    throw Error(err);
  });
}

export async function restoreCandidate(id) {
  return axios.patch("/candidate/" + id, { jp_is_deleted: false }).catch((err) => {
    throw Error(err);
  });
}

export async function updateCandidate(candidate, id) {
  const response = await axios.put("/candidate/" + id, candidate, {
    headers: { "Content-Type": "multipart/form-data" }
  });
  return response.data;
}

export async function getCandidateResume(id, filename) {
  try {
    const response = await axios.get("/candidate/resume", {
      params: { id: id },
      responseType: "blob"
    });
    if (response.data) {
      return response.data;
    } else {
      return [];
    }
  } catch (e) {
    console.log(e);
  }
}

export async function updateCandidatePassword(id, newPass, oldPass, reset = false) {
  try {
    const response = await axios.patch(
      "/candidate/change-password",
      {
        id,
        newPass,
        oldPass,
        reset
      },
      { "Content-Type": "application/json" }
    );
    return response;
  } catch (e) {
    return e;
  }
}

export async function getCandidateId(email) {
  try {
    const response = await axios.get("/candidate/get-id-by-email", { params: { email } });
    return response.data;
  } catch (error) {
    console.log(error);
  }
}
