import { getJobListingByCompany } from "app/api/job_api";
import { useQuery } from "@tanstack/react-query";
import useAuth from "../useAuth";
const useGetCompanyJobs = ({ sortOrder, column, limit, offset, id, searchQuery, ...rest }) => {
  const { user } = useAuth();

  return useQuery({
    queryKey: ["company_job_listings", sortOrder, column, limit, offset, searchQuery, rest],
    queryFn: () => getJobListingByCompany(searchQuery, sortOrder, column, limit, offset, id),
    enabled: user.role === "company"
  });
};

export default useGetCompanyJobs;
