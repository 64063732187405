import React from "react";
import { Box } from "@mui/material";
function PayRange({ children }) {
  return (
    <>
      <h3>Pay Range</h3>
      <Box display={"flex"} alignItems={"center"} gap={"10px"}>
        {children}
      </Box>
    </>
  );
}

export default PayRange;
