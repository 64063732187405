import { useQuery } from "@tanstack/react-query";
import { getCompanyLogo } from "app/api/company_api";

const useGetCompanyLogo = ({ companyId }) => {
  return useQuery({
    queryKey: ["company_id", companyId],
    queryFn: () => getCompanyLogo(companyId),
    initialData: {}
  });
};

export default useGetCompanyLogo;
